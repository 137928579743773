import moment from "moment";

export const isEmail = (email) => {
  if(!email) {
      return false
  }
  let regex = /^([a-zA-Z0-9_.+-Çç])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  return regex.test(email);
}

export const getDayName = (timestamp) => {
  let day = moment(timestamp?.toJSDate()).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd D MMM',
    lastDay: '[Yesterday] D MMM',
    lastWeek: 'dddd D MMM',
    sameElse: 'dddd D MMM'
  })
  return day
}