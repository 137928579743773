import React, { Fragment, useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { fetchCustomers, fetchCustomersByRegion , addCustomer, removeCustomer, editCustomer } from '../../../state/actions/customers'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget';
import ModalAddCustomer from "./modals/modalAddCustomer";
import ModalRemoveCustomer from "./modals/modalRemoveCustomer";
import ModalEditCustomer from "./modals/modalEditCustomer";
import View from '@modul-connect/shared/components/atoms/view';
import Page from '@modul-connect/shared/components/atoms/page';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import { useHistory, useParams, Route } from 'react-router-dom';
import ModalAlert from './modals/modalAlert';
import Customer from './customer/customer'
import { P } from '@modul-connect/shared/components/atoms/text';
import theme from '@modul-connect/shared/theme';
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget'
import { enableUserRoles, regionNoCountry } from '../../../utils/global';
import UserRoleManagement from './userRoleManagement';

const Customers = ({
  themes,
  groupedCustomers,
  fetchCustomersByRegion ,
  fetchCustomers,
  customers,
  addCustomer,
  removeCustomer,
  editCustomer,
  savingLoadingCustomer,
  loading
 }) => {
  const [name, setName] = useState('');
  const [countryCode, setCountryCode] = useState(regionNoCountry.value);
  const [showAddCustomerModal, setShowAddCustomerModal] = React.useState(false)
  const [customerToRemoveId, setCustomerToRemoveId] = React.useState(null)
  const [removeErrorUsersNotEmpty, setRemoveErrorUsersNotEmpty] = React.useState(false)
  const [showEditCustomerModal, setShowEditCustomerModal] = React.useState(false)
  const [customerToEditId, setCustomerToEditId] = React.useState('')
  const [removeErrorVehiclesNotEmpty, setRemoveErrorVehiclesNotEmpty] = React.useState(false)
  const [customerToEditName, setCustomerToEditName] = React.useState('')
  const [customerToEditRegion, setCustomerToEditRegion] = React.useState('')
  let filterTimer = null

  const history = useHistory()
  const {id, subId, detail} = useParams()

  
  const [selectedUserId, setSelectedUserId] = useState(detail)

  const [selectedCustomerId, setSelectedCustomerId] = useState(null)
  const [selectedCountryCode, setSelectedCountryCode] = useState(null)

  useEffect(() => {
    setSelectedUserId(detail)
  }, [detail])

  const columnsCustomers =
  [{
    id: 'name',
    label: 'Customer',
    width: themes.device != 'mobile' && 70,
    hide: []
  }]
  
  React.useEffect(() => {
    // If the user is jumping to the "vehicle" page directly via link
    if (subId) {
      setSelectedCustomerId(subId)
    }
    if (id) setSelectedCountryCode(id)
    else setSelectedCountryCode(null)

    return () => {
      setSelectedCustomerId(null)
      setSelectedCountryCode(regionNoCountry.value)
    }
  },[id, subId])
  
  const regionCodeToFilterBy = selectedCountryCode === regionNoCountry.code ? regionNoCountry.value : selectedCountryCode
  let customers_prepared = customers?.data?.filter(customer => customer?.region === regionCodeToFilterBy)?.map(item => ({
    ...item,
    id: item.customerId,
    name: item.name ? item.name: null,
    nUsers: item.numberOfUsers ? item.numberOfUsers : null,
    nVehicles: item.numberOfVehicles ? item.numberOfVehicles : null,
  }))
  
  var customerDict = {}
  for (let i = 0; i < customers_prepared?.length; i++) {
    let customerInfo = {
      name: customers_prepared[i].name,
      nUsers: customers_prepared[i].nUsers,
      nVehicles: customers_prepared[i].nVehicles,
    }
    customerDict[customers_prepared[i].id] = customerInfo
  }

  function getCustomerName (customerId) {
    if (customers_prepared) {
      const customer = customers_prepared.find(c => c.customerId === customerId)
      return customer.name
    }
    return null
  }

  function getCustomerCountryCode(customerId) {
    if (customers_prepared) {
      const customer = customers_prepared.find(c => c.customerId === customerId)
        return customer.region ?? regionNoCountry.value
      }
    return regionNoCountry.value
  }

  const handleSearchStringChangeCustomer = event => {
    const filter = event.target.value
    if (filterTimer) clearTimeout(filterTimer)
    filterTimer = setTimeout(() => setName(filter), 1000)
  }

  const handleCountryCodeClick = (_, id, row) => {
    setName('')
    setSelectedCountryCode(row.countryCode)
    
    if(row.countryCode === regionNoCountry.value){
      history.push(`/manage/manage_customers/${regionNoCountry.code}`)
    }
    else {
      history.push(`/manage/manage_customers/${row.countryCode}`)
    }
  }


  const handleSearchStringChange = event => {
    const filter = event.target.value
    if (filterTimer) clearTimeout(filterTimer)
    filterTimer = setTimeout(() => setCountryCode(filter), 1000)
  }

  const handleClickCustomer = (event, id, row) => {   
    history.push(`/manage/manage_customers/${selectedCountryCode ?? regionNoCountry.code}/${id}`);
    setSelectedCustomerId(id)
  }

  const isSaving = savingLoadingCustomer?.addCustomer === 'saving' || savingLoadingCustomer?.removeCustomer === 'saving' || savingLoadingCustomer?.editCustomer === 'saving'

  return (
    <Fragment>
      <ModalAddCustomer
        openModal={ showAddCustomerModal }
        onClose={ () => setShowAddCustomerModal(false) }
        onSubmit={ (data) => {
          setTimeout(() => {
            history.push(`/manage/manage_customers/${data?.region ?? regionNoCountry.code}/${data.customerId}`)
          }, 500)
        }}
        countryCode={ selectedCountryCode}
        addCustomer={ addCustomer }
      />
     {
      selectedUserId && enableUserRoles
      ? <Route 
          path={ `/manage/manage_customers/${id}/${subId}/${selectedUserId}` }
          component={ () => <UserRoleManagement organisationId={ selectedCustomerId } email={ selectedUserId }/> }
        />
      :
       selectedCustomerId ? <Customer customerId={selectedCustomerId} setSelectedCustomerId={setSelectedCustomerId} />
        : selectedCountryCode ? <View>
            <SearchWidget
              title={ 'Customers' }
              onChange={ handleSearchStringChangeCustomer }
              placeholder={ 'Search for customer...' }
              linkTitle={ 'Add customer'}
              onClickLink={ () => setShowAddCustomerModal(true) }
              initialValue={ name }
          />
          <Page 
            linkBack={history}
            onClickLinkBack={() => setSelectedCountryCode(regionNoCountry.code)}>
            {
              isSaving && <P>Saving ...</P>
            }
    
            {savingLoadingCustomer?.addCustomer === 'failed' && <P large style={{ color: theme.colors.red }}>Adding customer failed. (Server error)</P>}
            {savingLoadingCustomer?.removeCustomer === 'failed' && <P large style={{ color: theme.colors.red }}>Removing customer failed. (Server error)</P>}
            {savingLoadingCustomer?.editCustomer === 'failed' && <P large style={{ color: theme.colors.red }}>Renaming customer failed. (Server error)</P>}
            <TableWidget
              loadingStatus={savingLoadingCustomer.fetchCustomers}
              data={ customers_prepared }
              totalItems={ customers?.total ?? 0 }
              columns={ columnsCustomers }
              onFetchData={ fetchCustomers }
              tag= {selectedCountryCode === regionNoCountry.code ? regionNoCountry.value : selectedCountryCode}
              onClickRow={ handleClickCustomer }
              searchStr={ name }
              themes={ themes }
              onRemove={ (event, row_id) => { setCustomerToRemoveId(row_id) } }
              onEdit={(event, row_id) => {
                setCustomerToEditId(row_id)
                setCustomerToEditName(getCustomerName(row_id))
                setShowEditCustomerModal(true)
                setCustomerToEditRegion(getCustomerCountryCode(row_id))
              }}
            />
          </Page>
    
          <ModalRemoveCustomer
            openModal={ customerToRemoveId ? true : false }
            onClose={ () => setCustomerToRemoveId(null) }
            onSubmit={ () => {
              if (customerDict && customerToRemoveId && customerDict[customerToRemoveId] && customerDict[customerToRemoveId].nUsers > 0) {
                setRemoveErrorUsersNotEmpty(true)
              }
              else if (customerDict && customerToRemoveId && customerDict[customerToRemoveId] && customerDict[customerToRemoveId].nVehicles > 0) {
                setRemoveErrorVehiclesNotEmpty(true)
              }
              else {
                removeCustomer(customerToRemoveId)
              }
              setCustomerToRemoveId(null)
            }}
            header={ ' Are you sure you want to remove the customer?' }
            content={ 'The customer with the name of \'' +
              ((customerDict && customerToRemoveId && customerDict[customerToRemoveId] && customerDict[customerToRemoveId].name) ?
                customerDict[customerToRemoveId].name :
                'NAME_UNDEFINED') +
              '\' will be deleted.'}
          />
    
        <ModalAlert // TODO: test this
          openModal={ removeErrorUsersNotEmpty || removeErrorVehiclesNotEmpty }
          onClose={ () => {
            setRemoveErrorUsersNotEmpty(false)
            setRemoveErrorVehiclesNotEmpty(false)
          }}
          header={'Cannot remove customer.'}
          content={
            removeErrorVehiclesNotEmpty ?
            'The customer can not be removed because it has vehicles attached. Please remove them first.' :
            'The customer can not be removed because it has users attached. Please remove them first.'
          }
          />
    
          <ModalEditCustomer
            openModal={showEditCustomerModal}
            selectedCountryCode = {selectedCountryCode}
            editCustomer={editCustomer}
            customerId={customerToEditId}
            prevCustomerName={ customerToEditName }
            onClose={ () => {
              setCustomerToEditId('')
              setCustomerToEditName('')
              setShowEditCustomerModal(false)
            }}
            prevCustomerRegion={customerToEditRegion}
          />
        </View>
      : <CountryCodeTablePage
          handleClick={handleCountryCodeClick}
          countryCode={countryCode}
          handleSearchStringChange={handleSearchStringChange}
          groupedCustomers={groupedCustomers}
          themes={themes}
          fetchCustomersByRegion={fetchCustomersByRegion}
          loadingStatus={loading?.fetchCustomersByCountry}
          setShowAddCustomerModal={setShowAddCustomerModal}
        />
    }
  </Fragment>
  )
}

const CountryCodeTablePage = ({
  countryCode,
  handleSearchStringChange,
  groupedCustomers,
  themes,
  handleClick,
  fetchCustomersByRegion,
  loadingStatus,
  setShowAddCustomerModal
}) => {
  const isLoading = loadingStatus === 'loading'
  const loadingFailed = loadingStatus === 'failed'

  const columns = [
    {
      id: 'countryCode',
      label: 'Country Code',
      width: themes.device != 'mobile' && 70,
      hide: []
    },
    {
      id: 'amnt',
      label: 'Customers',
      width: themes.device != 'mobile' && 70,
      hide: []
    }
  ]

  const grouped_prepared = groupedCustomers.data.map(r => {
    return {
      countryCode: r[0].countryCode,
      amnt: r.length
    }}
  )
  
  return (
    <Page>
      <SearchWidget
        title='Country Codes'
        placeholder='Search for country code...'
        linkTitle={'Add customer'}
        initialValue={countryCode}
        onChange={handleSearchStringChange}
        onClickLink={() => setShowAddCustomerModal(true) }
      />
      {
        isLoading ? <BubbleLoadingWidget text={ "Loading workshops ... " }/> : loadingFailed ? <P>{'Loading failed.'}</P> : null
      }
      <TableWidget
        hide={isLoading || loadingFailed}
        data={grouped_prepared}
        onFetchData={fetchCustomersByRegion}
        totalItems={groupedCustomers?.total ?? 0}
        columns={columns}
        searchStr={countryCode}
        themes={themes}
        onClickRow={handleClick}
      />
    </Page>
  )
}

const mapStateToProps = ({
  groupedCustomers,
  customers,
  themes,
  savingLoadingCustomer,
  loading
}) => ({
  groupedCustomers,
  customers,
  themes,
  savingLoadingCustomer,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchCustomersByRegion: (options) => dispatch(fetchCustomersByRegion(options)),
  fetchCustomers: (options) => dispatch(fetchCustomers(options)),
  addCustomer: (data) => dispatch(addCustomer(data)),
  removeCustomer: (customerId) => dispatch(removeCustomer(customerId)),
  editCustomer: (data) => dispatch(editCustomer(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Customers)