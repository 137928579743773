import React, {useEffect} from 'react'
import { Modal } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import View from '@modul-connect/shared/components/atoms/view'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
import Button from '@modul-connect/shared/components/atoms/button'
import modal from '@modul-connect/shared/components/atoms/modal'

const RenameWorkshopModal = ({
    openModal,
    onClose,
    renameWorkshop,
    disabled,
    currentName
}) => {
    const [name, setName] = React.useState('')
    const [errorName, setErrorName] = React.useState(false)

    useEffect(() => {
        if(openModal) {
            setName(null)
            setErrorName(null)
        }
    }, [openModal])

    const isName = () => name?.length && name !== currentName

    const onSave = () => {
        let hasErrors

        if(!isName()) {
            setErrorName(true)
            hasErrors = true
        }

        if(hasErrors) return
    
        renameWorkshop(name)
        
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={ onClose }            
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={'Edit workshop'}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name='New Workshop Name'
                                placeholder={ currentName ? currentName : 'e.g. WoodburnGreen' }
                                disabled={ disabled }
                                onBlur={ e => setErrorName(!isName(e.target.value)) }
                                error={errorName}
                                onChange={ e => setName(e.target.value) }
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel disabled={ disabled } onClick={ onClose }>
                                Cancel
                            </Button>
                            <Button noBg noPadding disabled={ disabled } onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default RenameWorkshopModal