import Page from '@modul-connect/shared/components/atoms/page'
import { H3, H4 } from '@modul-connect/shared/components/atoms/text'
import View from '@modul-connect/shared/components/atoms/view'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import { BubbleLoader } from 'react-css-loaders'
import theme from '@modul-connect/shared/theme'
import { fetchUser, moveUser } from '../../../../../state/actions/customers'
import { fetchWorkshop } from '../../../../../state/actions/workshops'
import TreeWidget from '@modul-connect/shared/components/molecules/treeWidget'
import { useAuth0 } from '@auth0/auth0-react';

const Roles = {
  standard: 'standard',
  admin: 'admin',
  super: 'super'
}

function translateRole(role) {
  switch(role) {
    case Roles.standard:
      return 'a technician, with no access to the backoffice.'
    case Roles.admin:
      return 'an admin, with access to their workshop in backoffice.'
    case Roles.super:
      return 'a superadmin, with access to to everything in backoffice.'
    default:
      return 'a user, but not related to this workshop.'
  }
}

export const Technician = ({
  email, workhshopId, workshop, user, fetchWorkshop, fetchUser, moveUser, loading
}) => {
  const auth = useAuth0()

  const history = useHistory()

  const [role, setRole] = useState(undefined)

  const isLoading = loading?.fetchUser?.[email] === 'loading'
  const loadingFailed = loading?.fetchUser?.[email] === 'failed'

  useEffect(() => {
    if (workshop.tree?.uuid !== workhshopId)
      fetchWorkshop(workhshopId)
  }, [workhshopId])

  useEffect(() => {
    if (user.email !== email && !isLoading)
      fetchUser(email)
  }, [email])

  useEffect(() => {
    const currentRole = user.user_metadata?.info.tags.find(x => x.tags === workhshopId)?.privilege
    if (currentRole) setRole(currentRole)
  }, [user])

  useEffect(() => {
    const currentRole = user.user_metadata?.info.tags.find(x => x.tags === workhshopId)?.privilege
    if (currentRole && role && role !== currentRole)
      moveUser({ email, organisation: workhshopId, privilege: role })
  }, [role])

  const isYou = auth.user?.email === email

  return (
    user.email !== email || workshop.tree?.uuid !== workhshopId
    ?
    <View extend={ styles.loaderContainer }>
      <BubbleLoader
        color={ theme.colors.primary }
        size={ 5 }
      />
      <H3>Fetching technicians ...</H3>
    </View>
    :
    <Page title={ workshop.name + ': ' + user.firstName + ' ' + user.lastName + (isYou ? ' (your account)' : '')} linkBack={ history }>
      {/* <H3>{ (isYou ? 'You are ' : user.firstName + ' is ') + translateRole(role) }</H3> */}
      { isYou ? 
        <H4>Making changes to your own account is prohibited. You assigned as {translateRole(role)}</H4> :
        <TreeWidget
          hideRoot
          title={ 'Role'}
          tree={{
              uuid: 1, name: 'Role', 
              children: [
                  {uuid: Roles.standard, name: 'Standard (cannot access backoffice)'},
                  {uuid: Roles.admin, name: 'Admin (can access their workshop in backoffice)'},
                  {uuid: Roles.super, name: 'Super (can access everything in backoffice)'}
              ]
          }}
          onSelect={ (uuid, _) => uuid !== 1 && setRole(uuid) }
          selected={ role }
          disabled={ isYou }
        />
    
      }
    </Page>
  )
}

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding_style: {
    marginRight: 80,
    width: 350
  },
  padding: {
    marginBottom: '40px'
  },
  loaderContainer: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }
}

const mapStateToProps = ({
  workshop,
  user,
  loading
}) => ({
  workshop,
  user,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchWorkshop: workshopId => dispatch(fetchWorkshop(workshopId)),
  fetchUser: email => dispatch(fetchUser(email)),
  moveUser: data => dispatch(moveUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Technician)
