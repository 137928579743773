import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { fetchFirmwareReport, fetchVehiclesByFirmware } from "../../../state/actions/vehicles"
import firmware_columns from "./firmware_columns"
import Page from "@modul-connect/shared/components/atoms/page";
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget";
import moment from "moment"
import { Fragment } from "react";
import { useHistory } from 'react-router-dom';
import FirmwareCharts from "./firmware_charts";

const FirmwareReport = ({vehicles, firmwares_report, fetchFirmwareReport, fetchVehicles, themes, loading}) => {
  const history = useHistory()

  const vehicles_prepared = vehicles.data ? vehicles.data.map(item => {
    return {
      ...item,
      id: item.mainbox_id ?? null,
      vin: item.vehicle?.vin ?? '-',
      serialId: item.mainbox_id.split('_').pop() ?? null,
      telematicsUnit: item?.telematics_id ? 'yes' : 'no',
      model: item.vehicle?.brandName ? `${item.vehicle.brandName} ${item.vehicle.modelName ?? ''}` : null,
      order_number: item.vehicle?.orderNumber,
      created_date: moment(item.created).calendar()
    }
  }) : []
  const [filter, setFilter] = useState({});
  const clearFilter = () => setFilter({})

   const handleClick = (event, id) => {
    history.push('/reports/general_vehicles/' + id);
   }

  useEffect(() => {
    fetchFirmwareReport({})
  }, [])

  return (
    <Fragment>
      {
        <FirmwareCharts
          loadingStatus={loading.fetchFirmwaresReport}
          firmwares_report={firmwares_report} 
          filter={filter}
          onSelect={setFilter}
          onDeselect={clearFilter}/>
      }

      <Page title={filter.firmware ? 'Vehicles running ' + filter.firmware.toUpperCase() + " " + filter.version : 'All vehicles'}>
        <TableWidget
          loadingStatus={loading.fetchVehicles}
          data={ vehicles_prepared }
          totalItems={ vehicles?.total ?? 0 }
          columns={ firmware_columns }
          onFetchData={ (options) => fetchVehicles({
            rowsPerPage: options.rowsPerPage,
            currentpage: options.currentpage,
            sort: options.sort,
            searchStr: options.searchStr,
            tag: options.tag,
            requireTelematics: false
          }) }
          fetchAgainOnChange={ false }
          onClickRow={ handleClick }
          searchStr={ filter }
          themes={ themes }
        />
      </Page>
    </Fragment>
  );
};

const mapStateToProps = ({
  vehicles,
  firmwares_report,
  themes,
  loading
}) => ({
  vehicles,
  firmwares_report,
  themes,
  loading
})

const mapDispatchToProps = dispatch => ({
  fetchFirmwareReport: (options) => dispatch(fetchFirmwareReport(options)),
  fetchVehicles: (options) => dispatch(fetchVehiclesByFirmware(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(FirmwareReport);
