import React, { useState, useEffect, Fragment } from 'react';
import theme from "@modul-connect/shared/theme";
import View from "@modul-connect/shared/components/atoms/view";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel } from '@material-ui/core';
import { useLoadingTimer } from '@modul-connect/shared/hooks';
import { H3, H5, P } from '../../atoms/text';
import Button from '../../atoms/button'
import { MdDelete, MdEdit, MdCheck, MdClose, MdFileDownload } from "react-icons/md";
import Tag from '../../atoms/tags/tag';
import { BubbleLoadingWidget } from '../bubbleLoadingWidget/bubbleLoadingWIdget';

const TableWidget = ({
  data,
  totalItems,
  columns,
  onFetchData,
  onClickRow,
  searchStr,
  themes,
  extend,
  error,
  selectedRows,
  onRemove,
  editableIds,
  removableIds,
  onEdit,
  onDownload,
  rowsOptions,
  tag,
  defaultSort,
  isSortable=true,
  pagination=true,
  fetchAgainOnChange,
  sidescroll,
  loadingStatus,
  hide,
  isSaving,
}) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsOptions ? rowsOptions[0] : 10)
  const [sort, setSort] = useState(defaultSort ? defaultSort : 'created_datedesc')
  const [sortOrder, setSortOrder] = useState(
    defaultSort ? defaultSort.slice(-4) === 'desc' ? 'desc' : 'asc' 
    : 'desc')
  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false)
  const updateSearchStr = React.useRef(null);
  let currentpage = page + 1

  //const [showLoading, setShowLoading] = useState(false)
  //useLoadingTimer(setShowLoading)

  const isLoading = loadingStatus === 'loading'
  const loadingFailed = loadingStatus === 'failed'
  const noData = !data || data.length === 0

  useLoadingTimer(() => setShowTableWhileLoading(false))


  useEffect(() => {
    setRowsPerPage(rowsOptions ? rowsOptions[0] : 10)
  }, [rowsOptions])

  useEffect(() => {
    if(!onFetchData) {
      return
    }

    onFetchData({rowsPerPage, currentpage, sort, searchStr, tag})
  }, [rowsPerPage, currentpage, sort, searchStr, tag, fetchAgainOnChange])

  useEffect(() => {
    setShowTableWhileLoading(false)
  }, [rowsPerPage, searchStr, tag, fetchAgainOnChange])

  useEffect(() => {
    setShowTableWhileLoading(true)
  }, [sort, currentpage])

  useEffect(() => {
    if(!updateSearchStr.current !== searchStr) {
      setPage(0)
    }

    updateSearchStr.current = searchStr
  }, [searchStr]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSortBy = (sortValue) => {
    let order = 'asc'
    if (sortValue === sort){
      order = sortOrder === 'asc' ? 'desc' : 'asc'
    }
    order === 'asc' ? setSort(sortValue) : setSort(sortValue + 'desc')
    setSortOrder(order)
    setPage(0);
  }

  let columns_filtered
  switch (themes.device) {
    case 'tablet':
      columns_filtered = columns.filter(x => { return x.hide?.indexOf('tablet') === -1 })
      break
    case 'mobile':
      columns_filtered = columns.filter(x => { return x.hide?.indexOf('mobile') === -1 })
      break
    default:
      columns_filtered = columns
  }

  return (
    hide ? null :
    <View extend={[extend, error && styles.error, sidescroll && {overflowY: 'scroll'} ]}>
      { isSaving && <P>Saving ...</P>}
      { (!isLoading || showTableWhileLoading) && !noData ? (
        <Fragment>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns_filtered.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={ { fontFamily: 'futura', maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width  }}
                    className={ 'mc-table-head' }
                    onClick= {() => {
                      if (isSortable) {
                        handleSortBy(column.id)
                      }
                    }}
                  >
                    {column.label}
                    {isSortable ?
                      <TableSortLabel
                        active={sort === column.id || sort === column.id + 'desc'}
                        direction={sortOrder === 'asc' ? 'asc' : 'desc'}
                      >
                      </TableSortLabel> : null
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(0, rowsPerPage).map((row, index) => {
                if (row.isDivider) {
                  return (
                    <TableRow tabIndex={-1} key={row.id + "_" + index}>
                      <TableCell
                        style={ getDividerClass(index) }
                        className={ 'mc-table-divider' }
                        key={row.id}
                        colSpan={columns_filtered.length + (onEdit || onRemove || onDownload ? 1 : 0)}
                      >
                        <H5>{ row.name.toUpperCase() }</H5>
                      </TableCell>
                    </TableRow>
                  )
                }
                return (
                  <TableRow
                    style={ (selectedRows && selectedRows.includes(row.id)) ? styles.selected : null }
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id + "_" + index}
                  >
                    {
                      columns_filtered.map(column => {
                        let value, guard
                        if (column.hasGuard) {
                          value =  (row[column.id]?.value || row[column.id]?.value === 0) ? row[column.id].value : '-';
                          guard = (row[column.id]?.guard || row[column.id]?.value === 0) ? row[column.id].guard : null;
                        }
                        else value = (row[column.id] || row[column.id] === 0) ? row[column.id] : '-';
                        
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={event => {
                              const wasClickOnBtn = event.target.tagName === 'BUTTON' || event.target.tagName === 'IMG'
                              if (onClickRow && !wasClickOnBtn) {
                                onClickRow(event, row.id, row)
                              }
                            }}
                          >
                            <View extend={{display: 'flex'}}>
                              {
                                column.isTag ?
                                  <Tag textColor={column.textColor} value={value} tagColor={ column.tagColor ? column.tagColor(value, guard) : null} />
                                : column.isCheck && value === true ?
                                  <MdCheck/>
                                : column.isCheck ?
                                  <MdClose/>
                                : column.format && typeof value === 'number' ? column.format(value) : value
                              }
                              { column.showButton && column.showButton(value) &&
                                <Button noBg noPadding smaller
                                  extend={{marginLeft: '10px', '&:hover': { textDecoration: 'underline' }}}
                                  disabled={column.buttonDisabled(row)}
                                  onClick={ () => column.onClickButton && column.onClickButton(row) }>
                                  {typeof column.buttonTitle === 'function' ? column.buttonTitle(row) : column.buttonTitle  }
                                </Button>
                              }
                            </View>
                          </TableCell>
                        );
                      })
                    }
                    {
                      (onEdit || onRemove || onDownload) && (
                        <TableCell
                          key={ 'button' }
                          align={ 'right' }
                          style={ styles.action }
                          // onClick={event => {
                          //   if (onClickRow) {
                          //     onClickRow(event, row.id)
                          //   }
                          // }}
                        >
                        {
                          onEdit &&
                          (editableIds ? (editableIds.includes(row.id)) : true) && (
                              <MdEdit onClick={ (event) => onEdit(event, row.id) } />
                          )
                        }
                        {
                          onRemove &&
                          (removableIds ? (removableIds.includes(row.id)) : true) && (
                              <MdDelete style={{marginLeft: 10}} onClick={ (event) => onRemove(event, row.id) } />
                          )
                        }
                        {
                          onDownload &&
                          (removableIds ? (removableIds.includes(row.id)) : true) && (
                              <MdFileDownload style={{marginLeft: 10}} onClick={ (event) => onDownload(event, row.id) } />
                          )
                        }
                        </TableCell>
                      ) 
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {pagination &&
            <TablePagination
              showFirstButton
              showLastButton
              rowsPerPageOptions={rowsOptions || [10, 25, 50, 100]}
              component="div"
              count={ totalItems || 0 }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Fragment>
      ) : (
        <Fragment>
          {
            isLoading ? <BubbleLoadingWidget text={ "Loading ... " }/>
            : loadingFailed ? <H3>Could not fetch data.</H3> 
              : (noData ? <H3>No data to display.</H3> : null) 
          }
        </Fragment>
      )}
    </View>
  )
}

const styles = {
  error: {
    border: `1px solid ${ theme.colors.error }`
  },
  selected: {
    backgroundColor: theme.colors.primaryLight
  },
  action: {
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.colors.grey,
    fontSize: 18,
    width: 50
  },
  divider_no_padding: {
    padding_top: 0
  }
}

const getDividerClass = (index) => {
  return {
    color: theme.colors.grey,
    fontSize: 12,
    paddingBottom: 6,
    paddingTop: index ? 48 : 0,
    // fontWeight: 'bold',
    // backgroundColor: theme.colors.lightGrey,
  }
}

export default TableWidget;