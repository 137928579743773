import React, { useState} from 'react'
import { connect } from 'react-redux'
import { fetchTemplates } from '../../../state/actions/templates'
import SearchWidget from '@modul-connect/shared/components/molecules/searchWidget';
import View from '@modul-connect/shared/components/atoms/view';
import Page from '@modul-connect/shared/components/atoms/page';
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import { useHistory, useParams, Route } from 'react-router-dom';
import Template from './template/template'

const Templates = ({ themes, templates, fetchTemplates, savingLoadingCustomer }) => {
  const [name, setName] = useState('');
  let filterTimer = null

  const history = useHistory()
  const { id } = useParams()

  const [selectedTemplateId, setSelectedTemplateId] = useState(null)

  const columns = [
    {
      id: 'name',
      label: 'Template',
      width: themes.device != 'mobile' && 70,
      hide: []
    },
    {
      id: 'shortId',
      label: 'Saved From',
      width: themes.device != 'mobile' && 70,
      hide: []
    },
    {
      id: 'appliedTemplateDate',
      label: 'Latest application',
      width: themes.device != 'mobile' && 70,
      hide: []
    }
  ];

  React.useEffect(() => {
    // If the user is jumping to the "vehicle" page directly via link
    if (id) {
      setSelectedTemplateId(id)
    }

    return () => {
      setSelectedTemplateId(null)
    }
  }, [id])

  const templates_prepared = templates.data?.map((item, i) => ({
    ...item,
    id: item.id ?? `${item.boxId.replace('vehicle/', '')}~${item.name}`
  }))

  const setFilterAfterDelay = (newFilter) => {
    if (filterTimer)
      clearTimeout(filterTimer)
    
    filterTimer = setTimeout(() => { setName(newFilter) }, 1000)
  }

  const handleClick = (_, id) => {
    history.push(`/reports/others_templates/${id}`);
    setSelectedTemplateId(id)
  }

  if (selectedTemplateId) {
    return (
      <Route 
        path={`/reports/others_templates/${selectedTemplateId}`}
        component={() => <Template
          templateId={selectedTemplateId} 
          deselect={() => setSelectedTemplateId(null)} />}
      />
    )
  }

  return (
    <View>
      <SearchWidget
        title={ 'Templates' }
        onChange={ (event) => { setFilterAfterDelay(event.target.value) } }
        placeholder={ 'Search for template...' }
        initialValue={ name }
      />

      <Page>
        <TableWidget
          loadingStatus={savingLoadingCustomer.fetchCustomers}
          data={ templates_prepared }
          totalItems={ templates?.total ?? 0 }
          columns={ columns }
          onFetchData={ fetchTemplates }
          onClickRow={ handleClick }
          searchStr={ name }
          themes={ themes }
        />
      </Page>
    </View>
  )
}

const mapStateToProps = ({
  templates,
  themes,
  savingLoadingCustomer,
}) => ({
  templates,
  themes,
  savingLoadingCustomer,
})

const mapDispatchToProps = dispatch => ({
  fetchTemplates: (options) => dispatch(fetchTemplates(options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
