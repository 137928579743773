import Dashboard from './pages/dashboard'
// import Firmwares from './pages/firmwares/firmwares'
import Manage from './pages/manage/manage'
import Reports from './pages/reports/reports'

export default [
  {
    url: '/',
    name: 'Dashboard',
    exact: true,
    component: Dashboard,
    icon: 'MdDashboard',
    navbar: true
  },
  // {
  //   url: '/firmwares/',
  //   name: 'Firmwares',
  //   component: Firmwares,
  //   icon: 'MdBusiness',
  //   navbar: true,
  //   exact: true
  // },
  {
    url: '/reports/general_vehicles',
    name: 'Reports',
    exact: true,
    component: Reports,
    icon: 'MdViewAgenda',
    navbar: true
  },
   {
    url: `/reports/:option/:id?`,
    name: 'Reports',
    component: Reports,
  }, 
  {
    url: '/manage/manage_workshops',
    name: 'Manage',
    exact: true,
    component: Manage,
    icon: 'MdViewAgenda',
    navbar: true
  },
  {
    url: `/manage/:option/:id?/:subId?/:detail?`,
    name: 'Manage',
    component: Manage,
  }
]
