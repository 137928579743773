import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import { H3, P } from '@modul-connect/shared/components/atoms/text'
import View from '@modul-connect/shared/components/atoms/view'
import theme from '@modul-connect/shared/theme';
import React from 'react'

export const MainboxDebugInfo = ({
  padding_style,
  debug_status,
  enableDebug,
  disableDebug
}) => {
  const [checked, setChecked] = React.useState(debug_status)
  
  return (
    <View extend={ padding_style }>
      <H3>Debug</H3>
      
      <View extend={ styles.switchWrapper }>
        <P strong>
          Enable gnss debug mode
          <ThemeProvider theme={ theme.mui }>
            <Switch
              checked={ checked }
              onChange={() => {
                checked ? disableDebug() : enableDebug()
                setChecked(!checked)
              }}
              color={ "primary" }
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </ThemeProvider>
        </P>
      </View>
    </View>
  )
}

const styles = {
  switchWrapper:{
      "& p": {
          marginTop: 30,
          marginBottom: 0,
          display: 'flex',
          justifyContent: 'space-between',
          '> .MuiSwitch-root': {
              marginTop: -10,
          },
      }
  },
  upgradeStatus: {
    width: '60%'
  }
};