import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import Page from "@modul-connect/shared/components/atoms/page"
import View from "@modul-connect/shared/components/atoms/view"
import { H3, P } from "@modul-connect/shared/components/atoms/text"
import { fetchTemplate } from "../../../../state/actions/templates"
import { fetchTemplateVehicles } from "../../../../state/actions/vehicles"
import moment from "moment"
import { useHistory } from "react-router-dom"
import "./template.css"
import TableWidget from "@modul-connect/shared/components/molecules/tableWidget"
import vehicle_columns from "../../../reports/vehicles/vehicle_columns"
import { Code } from "@modul-connect/shared/components/atoms/code"
import { techstoreServerUrl } from '@modul-connect/shared/config'
import { sec } from "@modul-connect/shared/auth0/auth0Helper"


const Template = ({
  template,
  templateId,
  fetchTemplate,
  fetchTemplateVehicles,
  themes,
  deselect,
  loading,
}) => {
  let history = useHistory()

  const [showLoading, setShowLoading] = useState(false)
  const [accessories, setAccessories] = useState([])
  const [switches, setSwitches] = useState([])
  const [fetchedAccessories, setFetchedAccessories] = useState([])
  const [accessToken, setAccessToken] = useState(null)


  const template_vehicles_prepared =
    template.vehicles?.map((item) => ({
      ...item,
      id: item.templateBoxId,
      vin: item.vehicle ? item.vehicle.vin : null,
      serialId: item.vehicle ? item.mainbox_id.split("_").pop() : null,
      year: item.vehicle ? item.vehicle.year : null,
      brand_model: item.vehicle?.brandName
        ? item.vehicle.brandName +
          " " +
          (item.vehicle.modelName && item.vehicle.modelName !== "unknown"
            ? item.vehicle.modelName
            : "")
        : null,
      order_number: item.vehicle?.orderNumber,
      created_date: moment(item.created).calendar(),
      customerId: item.vehicle?.customerId,
    })) ?? []

    useEffect(() => {
      sec.getAccessTokenSilently().then((result) => {
        if(result != null){
          setAccessToken(result)
        }
})
    },[])

  const [templateBoxId, templateName] = templateId?.split("~")
  useEffect(() => {
    if (template) {
      fetchTemplate({ boxId: templateBoxId, name: templateName })
      const url = techstoreServerUrl() + '/switch'
      fetch(url, ).then((response) =>
        response.json().then((data) => {
          setSwitches(data.switches)
        })
      )
    }
  }, [templateId])

  useEffect(() => {
    const templateSinkItems = template.sinks?.toString()
    if (templateSinkItems) setAccessories(JSON.parse(templateSinkItems))
  }, [template])

    useEffect(() => {
    const apiUrl =  techstoreServerUrl() + '/accessory/v4'
    const authToken = accessToken
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
       },
    };

fetch(apiUrl, requestOptions)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json()
  })
  .then((data) => {
    if(data.accessories.length > 0)
    setFetchedAccessories(data.accessories)
    console.log('API response data:', data)
  })
  .catch((error) => {
    console.error('API request failed:', error)
  });

  },[accessToken])



  const accessoriesArray = accessories ? Object.values(accessories) : null
  let filteredAccessories = []
  if(accessoriesArray != null){

    for (const accessory of accessoriesArray) {
      if(accessory.Product_id == null){
        console.log('Already Exist')
      }
      else if (filteredAccessories.find((a) => a.AccessoryId == accessory.AccessoryId || a.Token == accessory.Token)) {
        console.log('Already Exist')
        } 
      else {
          filteredAccessories.push(accessory)
      }
    }}
    
    filteredAccessories.sort((a,b) => a.Config_header ? a.Config_header.localeCompare(b.Config_header ? b.Config_header : null) : null )

console.log('template', template)

  if (template?.name) {
    return (
      <View>
        <Page
          linkBack={history}
          onClickLinkBack={() => deselect()}
          title={template.name}
        >
          <View extend={styles.content}>
            <View extend={styles.padding_style}>
              <H3>General information</H3>
              <P>Configuration Template: {templateName}</P>
              <P>Saved from: {templateBoxId}</P>
              <P>
                Last applied:{" "}
                {new Date(template.appliedTemplateDate).toDateString()}
              </P>
            </View>
            <View extend={styles.padding_style}>
              <H3>Saved by</H3>
              <P>Technician: {template.technician ?? "Johan the Barbarian"}</P>
              <P>
                Workshop: {template.workshop?.name ?? "See/refine Test Lab"}
              </P>
              <P>
                Country: {template.workshop?.country ?? "Somewhere in space"}
              </P>
            </View>
          </View>
        </Page>

        { fetchedAccessories !== null && filteredAccessories !== null && filteredAccessories.length > 0 ? (
          <Page title={"Accessories "}>
            <View style={styles.padding_style}>
              <H3>{`Products (${filteredAccessories.length}) : `} </H3>
              {filteredAccessories.map((key, index) => {
                let name
                if (filteredAccessories[index].Token != null) {
                  let accessoryList = fetchedAccessories.find((item) => {return item.productId == filteredAccessories[index].Product_id })
                  if(accessoryList != null){
                    name = accessoryList.productName
                  }
                  else{
                    name = 'Unnamed accessory'
                  }
                }
                if (
                  filteredAccessories[index].Product_id === "01-IAW0000300" ||
                  filteredAccessories[index].Product_id === "01-IAW0000283"
                ) {
                  name = "Clayton LPS || 3000W"
                }

                if (
                  filteredAccessories[index].Product_id === "22033-03-1"
                ) {
                  name = "12Vdc power socket - Remote"
                }

                return (
                  <P key={filteredAccessories[index].AccessoryId + "_" + index}>
                    {name}
                  </P>
                )
              })}
            </View>
          </Page>
        ) : null}

        <Page title="Configuration details">
          <Code syntax={JSON.stringify(JSON.parse(template.sinks), null, 2)} />
        </Page>

        <Page title="Vehicles">
          <TableWidget
            data={template_vehicles_prepared}
            onFetchData={(options) => {
              if (loading.fetchVehicles !== "loading")
                fetchTemplateVehicles({
                  ...options,
                  name: templateName,
                  templateBoxId: templateBoxId,
                })
            }}
            totalItems={template?.vehicles_total ?? 0}
            columns={vehicle_columns}
            themes={themes}
            extend={{ marginTop: 20 }}
            onClickRow={(_, __, vehicle) => {
              history.push("/reports/general_vehicles/" + vehicle.mainbox_id)
            }}
            sidescroll
          />
        </Page>
      </View>
    )
  } else {
    return <Page>{showLoading && <H3 children={"Loading..."} />}</Page>
  }
}

const styles = {
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  padding_style: {
    marginRight: 80,
    width: 350,
    paddingLeft: "2%",
  },
  padding: {
    marginBottom: "40px",
  },
  modalTable: {
    border: "3px solid red",
  },
}

const mapStateToProps = ({
  template,
  vehicles,
  vehicle,
  themes,
  saving,
  loading,
  savingCustomerFleet,
}) => ({
  template,
  vehicles,
  vehicle,
  themes,
  saving,
  loading,
  savingCustomerFleet,
})

const mapDispatchToProps = (dispatch) => ({
  fetchTemplate: (templateId) => dispatch(fetchTemplate(templateId)),
  fetchTemplateVehicles: (options) => dispatch(fetchTemplateVehicles(options)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Template)
