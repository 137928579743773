import React, {useEffect} from 'react';
import { FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import View from '@modul-connect/shared/components/atoms/view'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal';
import { regionNoCountry, regions } from '../../../../utils/global';
import theme from '@modul-connect/shared/theme';

const ModalEditCustomer = ({
    openModal,
    onClose,
    editCustomer,
    disabled,
    customerId,
    prevCustomerName,
    prevCustomerRegion
}) => {
    const [customerName, setCustomerName] = React.useState(prevCustomerName)
    const [customerRegion, setCustomerRegion] = React.useState(prevCustomerRegion)
    const [errorCustomerName, setErrorCustomerName] = React.useState(false)
    
    useEffect(() => {
        if(openModal) {
            setCustomerName(prevCustomerName)
            setErrorCustomerName(null)
            setCustomerRegion(prevCustomerRegion)
        }
    }, [openModal])

    const isName = (name) => {
        if(!name) {
            return false
        }

        if(!name.length) {
            return false
        }

        return true
    }

    const onSave = () => {
        const trimmedName = customerName?.trim()
        const nameWasUpdated = trimmedName && trimmedName !== prevCustomerName
        const regionWasUpdated = customerRegion !== prevCustomerRegion

        if (!nameWasUpdated && !regionWasUpdated) {
          onClose()
          return
        }

        let hasErrors
        if(!isName(trimmedName) && !regionWasUpdated ) {
            setErrorCustomerName(true)
            hasErrors = true
            return
        }

        const data = {
          region: regionWasUpdated ? customerRegion : prevCustomerRegion,
          customerName: nameWasUpdated ? trimmedName : prevCustomerName, 
          customerId: customerId
        }
    
        editCustomer(data)
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Edit customer"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="New Customer Name"
                                placeholder={ prevCustomerName ? prevCustomerName : "Modul-System HH AB" }
                                disabled={ false }
                                onBlur={ (e) => setErrorCustomerName(!isName(e.target.value)) }
                                value={customerName ? customerName : prevCustomerName}
                                error={errorCustomerName}
                                onChange={e => setCustomerName(e.target.value)}
                            />
                            <AddRegion noCountryNotAllowed={prevCustomerRegion ? true : false} customerRegion={customerRegion} setCustomerRegion={setCustomerRegion}/>
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel disabled={ disabled } onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg noPadding disabled={ disabled } onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}


export const AddRegion = ({customerRegion, setCustomerRegion, noCountryNotAllowed}) => {
  return(
    <FormControl fullWidth >
      <InputLabel style={{ fontFamily: 'Futura', color: theme.colors.darkGrey }} id="demo-multiple-name-label">Country Code</InputLabel>
      <Select
        labelId="demo-multiple-name-label"
        id="demo-multiple-name"
        style={{ fontSize: 14, fontFamily: 'Futura', color: theme.colors.black, paddingLeft: 8 }}
        // null values are not allowed ...
        value={customerRegion === regionNoCountry.value ? regionNoCountry.displayToUser : customerRegion}
        onChange={e => {
          if (e.target.value === regionNoCountry.displayToUser) setCustomerRegion(regionNoCountry.value)
          else setCustomerRegion(e.target.value)
        }}
        label={"Country Code"}
        >
        {regions.map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
        {!noCountryNotAllowed ? <MenuItem key={regionNoCountry.code} value={regionNoCountry.displayToUser}>
          {regionNoCountry.displayToUser}
        </MenuItem> : null}
      </Select>
    </FormControl>
  )
}


export default ModalEditCustomer;