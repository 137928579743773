import React from "react";
import { SpinLoader } from "react-css-loaders";
import theme from "../../../theme";
import View from "../../atoms/view";

const loaderContainerStyle = {
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",

  "> div": {
    marginTop: 0,
    marginBottom: 0,
  },
};

export const Spinner = ({ size, color, extend }) => {
  return (
    <View extend={[loaderContainerStyle, extend]}>
      <SpinLoader color={color ?? theme.colors.primary} size={size} />
    </View>
  );
};
