import React, {useEffect} from 'react';
import { Modal } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'
import { regionNoCountry } from '../../../../utils/global';
import { AddRegion } from './modalEditCustomer';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const ModalAddCustomer = ({
    openModal,
    onClose,
    addCustomer,
    disabled,
    onSubmit,
    countryCode
}) => {
    const [customerName, setCustomerName] = React.useState('')
    const [errorCustomerName, setErrorCustomerName] = React.useState(false)
    const [customerRegion, setCustomerRegion] = React.useState(countryCode === regionNoCountry.code ? regionNoCountry.value : countryCode)

    useEffect(() => {
        if(openModal) {
            setCustomerName(null)
            setErrorCustomerName(null)
            setCustomerRegion(countryCode === regionNoCountry.code ? regionNoCountry.value : countryCode)
        }
    }, [openModal])

    const isName = (name) => {
      if(!name) return false
      if(!name.trim().length) return false
      
      return true
    }

    const onSave = () => {
        let hasErrors

        if(!isName(customerName)) {
            setErrorCustomerName(true)
            hasErrors = true
        }

        if(hasErrors) {
            return
        }

        const data = {
            region: customerRegion,
            customerName: customerName.trim(),
            customerId: uuidv4()
        }

        addCustomer(data)
        onSubmit(data)
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Add customer"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="Customer Name"
                                placeholder="Modul-System HH AB"
                                disabled={ disabled }
                                error={errorCustomerName}
                                onChange={e => setCustomerName(e.target.value)}
                            />
                            <AddRegion
                              customerRegion={customerRegion === regionNoCountry.code ? regionNoCountry.value : customerRegion} 
                              setCustomerRegion={setCustomerRegion}
                            />
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel disabled={ disabled } onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg noPadding disabled={ disabled } onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAddCustomer;