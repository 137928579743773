import Page from '@modul-connect/shared/components/atoms/page'
import React, { Fragment, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addWorkshop, fetchWorkshop, removeWorkshop, renameWorkshop } from '../../../../state/actions/workshops'
import { fetchWorkshopUsers } from '../../../../state/actions/users'
import ModalAddWorkshop from './modals/addWorkshopModal'
import RenameWorkshopModal from './modals/renameWorkshopModal'
import RemoveModal from './modals/removeModal'
import ModalAddUser from './modals/addUserModal'
import { addUser, removeUser, sendInvite } from '../../../../state/actions/customers'
import moment from 'moment'
import { BubbleLoadingWidget } from '@modul-connect/shared/components/molecules/bubbleLoadingWidget/bubbleLoadingWIdget'
import { WorkshopsSubpage } from './subpages/subpage_workshops'
import { TechniciansSubpage } from './subpages/subpage_technicians'

const Workshop = React.memo(({
  themes,
  workshopId,
  subWorkshopId,
  fetchWorkshop,
  workshop,
  fetchWorkshopUsers,
  addWorkshop,
  removeWorkshop,
  renameWorkshop,
  addUser,
  removeUser,
  saving,
  loadingWorkshop,
  sendInvite
}) => {
  const history = useHistory()

  const [showAddWorkshopModal, setShowAddWorkshop] = useState(false)
  const [showRenameWorkshopModal, setShowRenameWorkshop] = useState(false)
  const [showRemoveModal, setShowRemove] = useState(false)
  const [showAddUserModal, setShowAddUser] = useState(false)
  const [toRemove, setToRemove] = useState(undefined)

  const Remover = useCallback(() =>
    <RemoveModal
      openModal={showRemoveModal ? true : false}
      onClose={() => setShowRemove(undefined)}
      onSubmit={() => {
        toRemove === 'workshop'
          ? removeWorkshop(showRemoveModal)
          : removeUser({ email: showRemoveModal, tag: subWorkshopId })
        setShowRemove(undefined)
      }}
      name={toRemove === 'workshop'
        ? workshop.tree.children.find(x => x.uuid === showRemoveModal)?.name
        : users_prepared.find(x => x.id === showRemoveModal)?.name
      }
      type={toRemove}
    />,
    [showRemoveModal]
  )

  const handleClick = (_, id) => {
    history.push(`/manage/manage_workshops/${workshopId}/${id}`)
  }
  const handleClickUser = (_, id) => history.push(`/manage/manage_workshops/${workshopId}/${subWorkshopId}/${id}`)

  const users_prepared = workshop?.users?.length ? workshop.users.map(user => ({
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    registered: moment(user.created).calendar(),
    id: user.email,
    verified: user.isVerified ? 'verified' : 'not verified'
  })) : []

  const subpage = subWorkshopId ? 'TECHNICIANS' : 'WORKSHOPS'

  const isLoading = subpage === 'WORKSHOPS' ? 
    workshop.workshopId !== workshopId
    : workshop.users_workshopId !== subWorkshopId

  return (
    <Fragment>
      <Page
        linkBack={history}
        title={ subWorkshopId ? (workshop?.tree?.children?.find(x => x.uuid === subWorkshopId)?.name ?? workshop.name) : workshop.name}
      />

      {isLoading && <Page> <BubbleLoadingWidget text={'Loading workshop ...'} /> </Page>}

      {subpage === 'WORKSHOPS' &&
        <WorkshopsSubpage
          hide={isLoading || subpage !== 'WORKSHOPS'}
          saving={saving}
          loading={loadingWorkshop}
          workshop={workshop}
          handleClick={handleClick}
          fetchWorkshop={fetchWorkshop}
          setToRemove={setToRemove}
          setShowRemove={setShowRemove}
          setShowRenameWorkshop={setShowRenameWorkshop}
          setShowAddWorkshop={setShowAddWorkshop}
          themes={themes}
          workshopId={workshopId}
        />}

      {subpage === 'TECHNICIANS' &&
       <TechniciansSubpage 
        hide={isLoading || subpage !== 'TECHNICIANS'}
        setShowAddUser={setShowAddUser}
        styles={styles}
        users_prepared={users_prepared}
        workshop={workshop}
        themes={themes}
        handleClickUser={handleClickUser}
        setToRemove={setToRemove}
        setShowRemove={setShowRemove}
        fetchWorkshopUsers={fetchWorkshopUsers}
        workshopId={subWorkshopId}
        saving={saving}
        loading={loadingWorkshop}
        sendInvite={sendInvite}
      />}
      
      <ModalAddWorkshop
        openModal={showAddWorkshopModal}
        onClose={() => setShowAddWorkshop(false)}
        addWorkshop={name => {
          addWorkshop(name, workshop.tree.uuid)
        }}
      />
      <RenameWorkshopModal
        openModal={showRenameWorkshopModal ? true : false}
        onClose={() => setShowRenameWorkshop(undefined)}
        renameWorkshop={name => renameWorkshop(showRenameWorkshopModal, name)}
        currentName={showRenameWorkshopModal ? workshop.tree.children.find(x => x.uuid === showRenameWorkshopModal)?.name : undefined}
      />
      <Remover />
      <ModalAddUser
        openModal={showAddUserModal}
        onClose={() => setShowAddUser(false)}
        workshopId={subWorkshopId}
        addUser={addUser}
      />
    </Fragment>
  )
})

const styles = {
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  padding_style: {
    marginRight: 80,
    width: 350
  },
  padding: {
    marginBottom: '40px'
  }
}

const mapStateToProps = ({
  themes,
  workshop,
  vehicles,
  saving,
  loadingWorkshop
}) => ({
  themes,
  workshop,
  vehicles,
  saving,
  loadingWorkshop
})

const mapDispatchToProps = dispatch => ({
  fetchWorkshop: (id, options) => dispatch(fetchWorkshop(id, options)),
  fetchWorkshopUsers: options => dispatch(fetchWorkshopUsers(options)),
  addWorkshop: (name, parentId) => dispatch(addWorkshop(name, parentId)),
  removeWorkshop: id => dispatch(removeWorkshop(id)),
  renameWorkshop: (id, newName) => dispatch(renameWorkshop(id, newName)),
  addUser: data => dispatch(addUser(data)),
  removeUser: data => dispatch(removeUser(data)),
  sendInvite: email => dispatch(sendInvite(email))
})

export default connect(mapStateToProps, mapDispatchToProps)(Workshop)