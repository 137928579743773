import React, {useEffect, useState} from 'react';
import { Modal } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import Input from '@modul-connect/shared/components/atoms/input'
import View from '@modul-connect/shared/components/atoms/view'
import { H1 } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'
import ScrollView from "@modul-connect/shared/components/atoms/scroll-view";
import moment from "moment"
import TableWidget from '@modul-connect/shared/components/molecules/tableWidget';
import { fetchVehicles } from "../../../../../state/actions/vehicles";
import { connect } from 'react-redux'
import vehicle_columns from '../../../../reports/vehicles/vehicle_columns'

const ModalAddVehicle = ({
    openModal,
    onClose,
    customer,
    vehicles,
    fetchVehicles,
    addVehicle,
    themes
}) => {
    const [selectedVehicles, setSelectedVehicles] = React.useState([])
    const [vin, setVin] = useState("");
    let filterTimer = null

    const fetchUnconnectedVehicles = (options) => {
        fetchVehicles({...options, notTag: customer.customerId})
    }

    const vehicles_prepared = vehicles.data ? vehicles.data.map(item => {
        return {
          ...item,
          id: item ? item.mainbox_id : null,
          vin: item.vehicle ? item.vehicle.vin : null,
          serialId: item.vehicle ? item.mainbox_id.split('_').pop() : null,
          year: item.vehicle ? item.vehicle.year : null,
          model: item.vehicle ? item.vehicle.brandName + " " + item.vehicle.modelName : null,
          order_number: item.vehicle ? item.vehicle.orderNumber : null,
          created_date: moment(item.created).calendar(),
          customerId: item.vehicle ? item.vehicle.customerId : null
        }
      }) : []

    useEffect(() => {
        if(openModal) {
            setSelectedVehicles([])
            setVin("")
        }
    }, [openModal])

    const selectVehicle = (event, row_id) => {
        if (selectedVehicles.includes(row_id)) {
            let selectedVehiclesNew = [...selectedVehicles]
            const index = selectedVehiclesNew.indexOf(row_id);
            selectedVehiclesNew.splice(index, 1);
            setSelectedVehicles(selectedVehiclesNew)
        }
        else {
            setSelectedVehicles([...selectedVehicles, row_id])
        }
    }

    const handleVinChange = (event) => {
      setFilterAfterDelay(event.target.value)
    }

    const setFilterAfterDelay = (newFilter) => {
      if (filterTimer) {
        clearTimeout(filterTimer)
      }
      
      filterTimer = setTimeout(() => {
        setVin(newFilter)
      }, 1000)
    }

    const onSave = () => {
        let i = 0
        while (i < selectedVehicles.length) {
            const data = {
                vehicleId: selectedVehicles[i],
                tag: customer.customerId,
            }

            addVehicle(data)
            i += 1
        }
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
              <div>
                <View extend={ modal.container_wide }>
                  <View extend={ modal.header }>
                    <H1 text={"Add vehicle to " + customer && customer.tree ? customer.tree.name : null}/>
                  </View>
                    <View extend={ modal.content }>
                      <Input
                          extend={ {
                              width: 250,
                              marginTop: 30,
                              marginBottom: 15
                          }}
                          type="text"
                          placeholder={ 'Search vehicle...' }
                          onChange={event => handleVinChange(event)}
                      />
                        <ScrollView>
                          <TableWidget
                              data={ vehicles_prepared }
                              totalItems={ vehicles?.total ?? 0 }
                              columns={ vehicle_columns }
                              onFetchData={ fetchUnconnectedVehicles }
                              onClickRow={ selectVehicle }
                              searchStr={ vin }
                              themes={ themes }
                              selectedRows={ selectedVehicles }
                              rowsOptions={[5, 20, 50]}
                          />
                        </ScrollView>
                      </View>

                  <View extend={ modal.footer }>
                    <Button noBg cancel onClick={ () => onClose() }>
                      Cancel
                    </Button>
                    <Button noBg noPadding onClick={ onSave }>
                      {'Submit (' + selectedVehicles.length + ')'}
                    </Button>
                  </View>
                </View>
              </div>
            </Fade>
        </Modal>
    )
}

const mapStateToProps = ({
    vehicles,
    themes,
}) => ({
    vehicles,
    themes,
})

const mapDispatchToProps = dispatch => ({
    fetchVehicles: (options) => dispatch(fetchVehicles(options)),
})

export default connect(mapStateToProps, mapDispatchToProps) (ModalAddVehicle);