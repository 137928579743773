import React from 'react';
import { Modal } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'

import { isEmail } from '@modul-connect/shared/utils/text'

const ModalAddress = ({
    title,
    openModal,
    onClose,
    onSubmit,
    customer,
    address
}) => {
    const [receiver, setReceiver] = React.useState(address?.receiver ?? '')
    const [streetAddress, setstreetAddress] = React.useState(address?.streetAdress ?? '')
    const [extraAddress, setextraAddress] = React.useState(address?.extraAdress ?? '')
    const [postalCode, setPostalCode] = React.useState(address?.postalCode ?? '')
    const [city, setCity] = React.useState(address?.city ?? '')
    const [division, setDivision] = React.useState(address?.division ?? '')
    const [country, setCountry] = React.useState(address?.country ?? '')
    const [contactEmail, setContactEmail] = React.useState(address?.contactEmail ?? '')
    const [contactPhone, setContactPhone] = React.useState(address?.contactPhone ?? '')

    const [pressedSubmit, setPressedSubmit] = React.useState(false)

    React.useEffect(() => {
      if (openModal) {
        setReceiver(address?.receiver ?? '')
        setstreetAddress(address?.streetAddress ?? '')
        setextraAddress(address?.extraAddress ?? '')
        setPostalCode(address?.postalCode ?? '')
        setCity(address?.city ?? '')
        setDivision(address?.division ?? '')
        setCountry(address?.country ?? '')
        setContactEmail(address?.contactEmail ?? '')
        setContactPhone(address?.contactPhone ?? '')
        setPressedSubmit(false)
      }

    }, [openModal])

    const onSave = () => {
      setPressedSubmit(true)

      if (!receiver || !streetAddress || !postalCode || !city || /* !division || */ !country || !contactEmail || !isEmail(contactEmail) /*  || !contactPhone */) {
        return
      }

        onSubmit(customer.customerId, { 
          receiver: receiver === '' ? null : receiver, 
          streetAddress: streetAddress === '' ? null : streetAddress, 
          extraAddress: extraAddress === '' ? null : extraAddress, 
          postalCode: postalCode === '' ? null : postalCode, 
          city: city === '' ? null : city, 
          division: division === '' ? null : division, 
          country: country === '' ? null : country, 
          contactEmail: contactEmail === '' ? null : contactEmail, 
          contactPhone: contactPhone === '' ? null : contactPhone 
        })
        onClose()
    }

    return (
        <Modal
            open={ openModal }
            onClose={ onClose }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={'Assign ' + title}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                name="Receiver"
                                placeholder= { address?.receiver ?? 'e.g. Sherlock Holmes' }
                                error={pressedSubmit && !receiver}
                                value={receiver}
                                onChange={e => setReceiver(e.target.value)}
                            />
                            <TextField
                                name="Address Row 1"
                                placeholder={ address?.streetAddress ?? 'e.g. 221B Baker Street' }
                                error={pressedSubmit && !streetAddress}
                                value={streetAddress}
                                onChange={e => setstreetAddress(e.target.value)}
                            />
                            <TextField
                                name="Address Row 2"
                                placeholder={ address?.extraAddress ?? 'e.g. c/o Watson' }
                                error={false}
                                value={extraAddress}
                                onChange={e => setextraAddress(e.target.value)}
                            />
                            <TextField
                                name="Postal Code"
                                placeholder={ address?.postalCode ?? 'e.g. NW1 6UY' }
                                error={pressedSubmit && !postalCode}
                                value={postalCode}
                                onChange={e => setPostalCode(e.target.value)}
                            />
                            <TextField
                                name="City"
                                placeholder={ address?.city ?? 'e.g. London' }
                                error={pressedSubmit && !city}
                                value={city}
                                onChange={e => setCity(e.target.value)}
                            />
                            {/* <TextField
                                name="Division"
                                placeholder={ address?.division ?? 'e.g. England' }
                                error={false}
                                value={division}
                                onChange={e => setDivision(e.target.value)}
                            /> */}
                            <TextField
                                name="Country"
                                placeholder={ address?.country ?? 'e.g. UK' }
                                error={pressedSubmit && !country}
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                            />
                            <TextField
                                name="Contact Email"
                                placeholder={ address?.contactEmail ?? 'e.g. sherlock@holmes.co.uk' }
                                error={pressedSubmit && (!contactEmail || !isEmail(contactEmail))}
                                value={contactEmail}
                                onChange={e => setContactEmail(e.target.value)}
                            />
                            {/* <TextField
                                name="Contact Phone"
                                placeholder={ address?.contactPhone ?? 'e.g. +44 XXXX XXXXXX' }
                                error={false}
                                value={contactPhone}
                                onChange={e => setContactPhone(e.target.value)}
                            /> */}
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg noPadding 
                              disabled={
                                !receiver ||
                                !streetAddress ||
                                !postalCode ||
                                !city ||
                                !country ||
                                !contactEmail ||
                                (
                                  receiver === (address?.receiver ?? '') &&
                                  streetAddress === (address?.streetAddress ?? '') &&
                                  postalCode === (address?.postalCode ?? '') &&
                                  city === (address?.city ?? '') &&
                                  country === (address?.country ?? '') &&
                                  contactEmail === (address?.contactEmail ?? '')
                                )
                                } onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAddress;