import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "@modul-connect/shared/components/atoms/view";
import Page from "@modul-connect/shared/components/atoms/page";
import SideBar from "@modul-connect/shared/components/organisms/side-bar";
import SideBarLink from "@modul-connect/shared/components/organisms/side-bar-link";
import MainContainer from "@modul-connect/shared/components/organisms/main-container";
import { useParams, useHistory } from "react-router-dom";
import ModalAjaxError from "@modul-connect/shared/components/organisms/modal-ajax-error";
import { dismissAjaxError } from "../../state/actions/app"
import Customers from "./customers/customers";
import Firmwares from "../manage/firmwares/firmwares"
import Workshops from "./workshops";

const Manage = ({ themes, saving, dismissAjaxError, role }) => {
  const history = useHistory()
  const { option } = useParams()

  const [selected, setSelected] = useState(null);
  const menuState = selected !== null;

  useEffect(() => {
    if (option) {
      const [category, title] = option.replace('-', ' ').split('_')

      let catIndex = sidebarContent.findIndex(x => category.includes(x.categoryId.toLowerCase()))

      if (catIndex < 0) {
        onSetSelected(0)
        return
      }

      let titleIndex = sidebarContent[catIndex].titles.findIndex(x => title.includes(x.title.toLowerCase()))

      if (titleIndex < 0) {
        onSetSelected(0)
        return
      }

      setSelected([catIndex, titleIndex])
    }
  }, [option])

  const onSetSelected = array => {
    let url = '/manage'

    if (array !== null) {
      url = url + '/' + sidebarContent[array[0]].categoryId.toLowerCase() +
                  '_' + sidebarContent[array[0]].titles[array[1]].title.replace(' ', '-').toLowerCase()
      history.push(url)
    }
  }

  const filterCategories = (category) => {
    category.titles = category.titles.filter(title => {
      return !title.role || title.role == role
    })
    return category
  }

  return (
    <Page>
      <View extend={styles.settings}>

      <ModalAjaxError
        openModal={ saving && saving.ajaxError }
        onClose={ dismissAjaxError }
      />
        <MainContainer themes={themes} extend={[ styles.main ]} >
          {menuState && (
            <View>
              {sidebarContent[selected[0]].titles[selected[1]].content}
            </View>
          )}
        </MainContainer>
        <SideBar themes={themes} extend={styles.sidebar}>
          {role ?
            sidebarContent.filter(category => { return filterCategories(category)}).map((category, catIndex) =>
            <SideBarLink
              key={catIndex}
              category={category}
              catIndex={catIndex}
              selected={selected}
              history={history}
              sidebarContent={sidebarContent}
              onClick={ onSetSelected }
            />
            ) :
            null 
          }
        </SideBar>
      </View>
    </Page>
  );
};

const sidebarContent = [
  {
    categoryId: 'manage',
    category: '',
    titles: [
      {title: 'Workshops', content: <Workshops />, icon: 'MdBuild' },
      {title: 'Customers', content: <Customers />, icon: 'MdBusiness', role: 1},
      {title: 'Firmwares', content: <Firmwares />, icon: 'MdCloud' },
      ]
  }
];

const styles = {
  settings: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  sidebar: ({ theme: { colors } }) => ({
    width: '200px',
    backgroundColor: colors.white,
    '> a': {
      paddingTop: '8px',
      transition: "color 200ms ease-in-out",
      '&:hover': {
        color: colors.primary
      },
      '&:active': {
        color: colors.white
      }
    }
  }),
  main: {
    padding: "0 16px",
    left: '200px',
    right: 0,
    bottom: 0
  },
};

const mapStateToProps = props => props;

const mapDispatchToProps = dispatch => ({
  dismissAjaxError: () => dispatch(dismissAjaxError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Manage);