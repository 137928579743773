import React from 'react';
import View from '@modul-connect/shared/components/atoms/view';
import { H1, P, A, Span } from '@modul-connect/shared/components/atoms/text';
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdAdd } from "react-icons/md";

const Page = ({ children, title, linkBack, onClickLinkBack, linkTitle, onClickLink, extend, linkIcon = <MdAdd />, linkDisabled }) => {
  function goBack() {
    linkBack.goBack();
  }

  const styles = {
    pageCSS: ({ theme: { layout, colors } }) => ({
      marginTop: `${2 * layout.grid}px`,
      paddingBottom: '18px',
      borderRadius: `${1 * layout.grid}px`,
      width: '100%',
      height: '100%',
      overflowY: 'visible',
      backgroundColor: colors.offWhite,
      "> h1": {
        marginBottom: 20
      }
    }),
    linkBack: ({ theme: { layout, colors } }) => ({
      paddingBottom: '8px',
      backgroundColor: "rgb(243, 243, 243)",
      position: 'relative',
      "> svg": {
        fontSize: 21,
        position: 'absolute',
        left: -6,
        top: -1
      },
      "> span": {
        paddingLeft: 17
      }
    }),
    icon: {
      fontSize: 21,
      position: 'absolute',
      right: 0,
      top: 8
    },
    titleWrapper: ({ theme: { layout, colors, shadowStyle } }) => ({
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: '#DDDDDD',
      paddingLeft: 26,
      alignItems: 'center',
      height: 34,
      "> div": {
        backgroundColor: '#DDDDDD',
        display: 'flex',
        flexDirection: 'row',
        color: colors.primary,
        cursor: 'pointer',
        "> p": {
          color: colors.primary
        }
      },
      ...shadowStyle
    }),
    disabled: ({ theme: { layout, colors } }) => ({
      "> div": {
        "> p": {
          color: colors.disabledGrey
        },
        cursor: 'default',
      }
    })
  }

  return (
    <View extend={[styles.pageCSS, extend]}>
      {linkBack && (
        <A extend={styles.linkBack}
          onClick={() => {
            goBack()
            onClickLinkBack && onClickLinkBack()
          }}>
          <MdKeyboardArrowLeft /><Span>BACK</Span>
        </A>
      )}
      {title ?
        <View extend={[styles.titleWrapper, linkDisabled ? styles.disabled : undefined]}>
          {<H1 text={title.toUpperCase()} />}
          {linkTitle && (
            <View onClick={linkDisabled ? undefined : () => onClickLink()}>
              {linkIcon}
              <P noPadding>{linkTitle.toUpperCase()}</P>
            </View>
          )}
        </View>
        : null
      }
      <View style={{      paddingLeft: '12px',
      paddingRight: '12px',}}>

      {children}
      </View>
    </View>
  )
};

export default Page;
