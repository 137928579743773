import { call, put } from 'redux-saga/effects'
import { get, post, upload } from "@modul-connect/shared/utils/ajax"
import fileDownload from 'js-file-download';
import { firmwareServerUrl, techstoreServerUrl } from '@modul-connect/shared/config';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"
import { format } from 'path';
import { regionNoCountry } from './global';


export function* fetchVehicle({ id }) {
  const url = "/box/" + id
  const vehicle = yield call(get, url)

  if(vehicle && vehicle.status === 'ok') {
      yield put({ type: 'VEHICLE_FETCHED', data: vehicle.data })
  }
}

export function* fetchVehicleConfig({ id }) {
  const url = "/box/config/" + id
  const config = yield call(get, url)

  if(config && config.status === 'ok') {
      yield put({ type: 'VEHICLE_CONFIG_FETCHED', data: config.data })
  }
}

function urlQuery(params) {
  return Object.entries(params)
    .filter(e => e[0] && e[1])
    .map(e => {
      const [k, v] = e
      return `${k}=${v}`
    })
    .join('&')
}

export function* fetchFirmwaresReport({ tag }) {
  const query = urlQuery({
    tag
  })
  const url = `/report/firmware?${query}`
  const response = yield call(get, url)

  if (response?.status === 'ok') {
    yield put({ type: 'FIRMWARES_REPORT_FETCHED', data: response.data })
  }
  else 
    yield put({ type: 'FETCHING_FIRMWARES_REPORT_FAILED' })
}

export function* fetchVehiclesByFirmware({ rowsPerPage, page, sort, searchStr, tag }) {
  const query = urlQuery({
    count: rowsPerPage,
    page: page,
    sortby: sort,
    tag: tag,
    firmware: searchStr.firmware,
    version: searchStr.version
  })
  const url = `/box?${query}`
  const vehicles = yield call(get, url)

  if (vehicles?.status === 'ok') {
    yield put({ type: 'VEHICLES_FETCHED', data: vehicles.data?.boxes ?? [], total: vehicles.data?.number ?? 0 })
  }
  else {
    yield put({ type: 'FETCHING_VEHICLES_FAILED' })
  }
}

export function* fetchVehicleHistory({ rowsPerPage, page, id }) {
  const url = "/box/history/" + id +
    "?count=" + rowsPerPage + 
    "&page=" + page
  const response = yield call(get, url)

  if (response?.status === 'ok') {
      yield put({ type: 'VEHICLE_HISTORY_FETCHED', data: response.data?.history, total: response.data && response.data.number ? response.data.number : 0 })
  }
}

export function* fetchVehicleTextRecords({ desc, rowsPerPage, page, id }) {
  const url = "/box/text-records/" + id +
    "?desc=" + desc +
    "&count=" + rowsPerPage + 
    "&page=" + page
  const response = yield call(get, url)

  if(response && response.status === 'ok') {
      yield put({ type: 'VEHICLE_TEXT_RECORDS_FETCHED', data: response.data?.records, total: response.data && response.data.number ? response.data.number : 0 })
  }
}

export function* fetchVehicles({ rowsPerPage, page, sort, searchStr, tag, requireTelematics, notTag, workshop, country }) {
  const url = "/box?count=" + rowsPerPage + "&page=" + page + "&sortby=" + sort + "&filter=" + searchStr + "&tag=" + tag +
              "&requireTelematics=" + requireTelematics + "&notTag=" + notTag + "&country=" + country + "&workshop=" + workshop
  const vehicles = yield call(get, url)

  if(vehicles && vehicles.status === 'ok') {
    yield put({ type: 'VEHICLES_FETCHED', data: vehicles.data && vehicles.data.boxes ? vehicles.data.boxes : [], total: vehicles.data && vehicles.data.number ? vehicles.data.number : 0 })
  }
  else {
    yield put({ type: 'FETCHING_VEHICLES_FAILED' })
  }
}

export function* fetchCustomerVehicles({ rowsPerPage, page, sort, searchStr, tag }) {
  const url = "/box?count=" + rowsPerPage + "&page=" + page + "&sortby=" + sort + "&filter=" + searchStr + "&tag=" + tag
  const vehicles = yield call(get, url)

  if(vehicles && vehicles.status === 'ok') {
      yield put({ type: 'CUSTOMER_VEHICLES_FETCHED', data: vehicles.data && vehicles.data.boxes ? vehicles.data.boxes : [], total: vehicles.data && vehicles.data.number ? vehicles.data.number : 0 })
  }
  else {
    yield put({ type: 'FETCHING_CUSTOMER_VEHICLES_FAILED' })
  }
}

export function* fetchTemplateVehicles({ templateBoxId, name, rowsPerPage, page, sort, searchStr, tag }) {
  const url = '/box'
  const searchCommand = {
    templateBoxId, appliedTemplateName: name,
    rowsPerPage, page, sort, filter: searchStr, tag
  }

  const vehicles = yield call(post, url, { body: JSON.stringify(searchCommand) }, 'query')

  if(vehicles && vehicles.status === 'ok') {
      yield put({ type: 'TEMPLATE_VEHICLES_FETCHED', data: vehicles.data && vehicles.data.boxes ? vehicles.data.boxes : [], total: vehicles.data && vehicles.data.number ? vehicles.data.number : 0 })
  }
  else {
    yield put({ type: 'FETCHING_TEMPLATE_VEHICLES_FAILED' })
  }
}

export function* fetchUsers({ rowsPerPage, page, sort, searchStr }) {
  const url = "/user?count=" + rowsPerPage + "&page=" + page + "&sortby=" + sort + "&namefilter=" + searchStr
  const users = yield call(get, url)

  if(users && users.status === 'ok') {
      yield put({ type: 'USERS_FETCHED', data: users.data && users.data.users ? users.data.users : [], total: users.data && users.data.number ? users.data.number : 0 })
  }
  else {
    yield put({ type: 'FETCHING_USERS_FAILED' })
  }
}

export function* fetchCustomerUsers ({ rowsPerPage, page, sort, searchStr, tag }) {
  const url = '/customer/' + tag + "/user?count=" + rowsPerPage + "&page=" + page + "&sortby=" + sort + "&namefilter=" + (searchStr ? searchStr : "")
  const response = yield call(get, url)

  if(response && response.status === 'ok') {
      yield put({ type: 'CUSTOMER_USERS_FETCHED', data: response.data })
  }
  else {
    yield put({ type: 'FETCHING_CUSTOMER_USERS_FAILED' })
  }
}

export function* fetchWorkshopUsers ({ rowsPerPage, page, sort, searchStr, tag }) {
  const url = '/workshop/' + tag + "/user?count=" + rowsPerPage + "&page=" + page + "&sortby=" + sort + "&namefilter=" + (searchStr ? searchStr : "")
  const response = yield call(get, url)

  if(response && response.status === 'ok') {
    yield put({ type: 'WORKSHOP_USERS_FETCHED', data: {...response.data, workshopId: tag } })
  }
  else
    yield put({ type: 'FETCHING_WORKSHOP_USERS_FAILED', data: { workshopId: tag } })
}

export function* fetchBoxesRegistered() {
  const boxesRegistered = yield call(get, '/box/count')

  if(boxesRegistered && boxesRegistered.status === 'ok') {
      yield put({ type: 'BOXES_REGISTERED_FETCHED', data: boxesRegistered.data })
  }
}

export function* fetchBoxesDisconnected() {
  const url = '/box/disconnected-boxes'
  const boxesDisconnected = yield call(get, url)

  if(boxesDisconnected && boxesDisconnected.status === 'ok' || boxesDisconnected.status === 'no_content'){
    yield put({ type: 'DISCONNECTED_BOXES_FETCHED', data: boxesDisconnected.data ? boxesDisconnected.data : [] })
  }
  else 
    yield put({type: 'FETCHING_DISCONNECTED_BOXES_FAILED' })
}

export function* fetchDisconnectedHistory(telematicsId) {
  const url = '/box/disconnected-box/connection-history/'+ telematicsId.telematicsId
  const disconnectedHistory = yield call(get, url)

  if(disconnectedHistory && disconnectedHistory.status === 'ok' || disconnectedHistory.status === 'no_content'){
    yield put({ type: 'DISCONNECTED_STATUS_HISTORY_FETCHED', data: disconnectedHistory.data ? disconnectedHistory.data : [] })
  }
  else
  yield put({ type: 'FETCHING_DISCONNECTED_STATUS_HISTORY_FAILED' })
} 

export function* fetchUsersRegistered() {
  const fetchUsersRegistered = yield call(get, '/user/count')

  if(fetchUsersRegistered && fetchUsersRegistered.status === 'ok') {
      yield put({ type: 'USERS_REGISTERED_FETCHED', data: fetchUsersRegistered.data })
  }
}

export function* fetchUserRole() {
  const fetchedUserRole = yield call(get, '/user/role')

  if(fetchedUserRole && fetchedUserRole.status === 'ok') {
      yield put({ type: 'USER_ROLE_FETCHED', data: fetchedUserRole.data })
  }
}

export function* fetchCustomers({ rowsPerPage, page, sort, searchStr, countryCode }) {  
  const url = "/customer/v2"
  const customers = yield call(get, url)
  if(customers && customers.status === 'ok') {
      const data = customers.data;
      const filtered = data.filter(c => (c.name.toLowerCase().includes(searchStr?.toLowerCase()) 
          && c.region === countryCode))
      const sortFunction = (a,b) => {
        switch(sort) {
          case "customerIddesc":
            return b.customerId.localeCompare(a.customerId)
          case "customerId":
          case "customerIdasc":
            return a.customerId.localeCompare(b.customerId)
          case "nUsersdesc":
            return b.numberOfUsers - a.numberOfUsers
          case "nUsers":
          case "nUsersasc":
            return a.numberOfUsers - b.numberOfUsers
          case "namedesc":
            return b.name.localeCompare(a.name)
          case "name":
          case "nameasc":
          default:
            return a.name.localeCompare(b.name)
        }
      }
      const sorted = filtered.sort((a,b) => sortFunction(a,b))
      const cut = sorted.length > rowsPerPage ? sorted.slice((page-1)*rowsPerPage, page*rowsPerPage) : sorted;
      yield put({ type: 'CUSTOMERS_FETCHED', data: cut, total: filtered.length })
  }
  else {
    yield put({ type: 'FETCHING_CUSTOMERS_FAILED' })

  }
}
export function* fetchCustomersByRegion({ rowsPerPage, page, sort, searchStr }) {
  const url = "/customer/groupbyregion-to-db"
  const customers = yield call(get, url)
  if(customers && customers.status === 'ok') {
      const data = customers.data;
      let filtered = []
      if (searchStr) {
        filtered = data.filter(u => (u[0]?.countryCode?.toLowerCase().includes(searchStr?.toLowerCase())));
      }
      else {
        filtered = data
      }
      const sortFunction = (a,b) => {
        switch(sort) {
          case "amnt":
            return a.length - b.length 
          case "amntdesc":
            return b.length - a.length
          case "countryCodedesc":
            return (a[0]?.countryCode===null)-(b[0]?.countryCode===null) || -(a[0]?.countryCode>b[0]?.countryCode) || +(a[0]?.countryCode<b[0]?.countryCode )
          case "countryCode":
            return (a[0]?.countryCode===null)-(b[0]?.countryCode===null) || +(a[0]?.countryCode>b[0]?.countryCode)||-(a[0]?.countryCode<b[0]?.countryCode )
          default:
            return (a[0]?.countryCode===null)-(b[0]?.countryCode===null) || +(a[0]?.countryCode>b[0]?.countryCode)||-(a[0]?.countryCode<b[0]?.countryCode )

        }
      }
      const sorted = filtered.sort((a,b) => sortFunction(a,b))

      const cut = sorted.length > rowsPerPage ? sorted.slice((page-1)*rowsPerPage, page*rowsPerPage) : sorted;
      yield put({ type: 'CUSTOMERS_BY_REGION_FETCHED', data: cut, total: filtered.length })
  }
  else {
    yield put({ type: 'FETCHING_CUSTOMERS_BY_REGION_FAILED' })

  }
}
export function* fetchCustomer({ id }) {
  let url = '/customer/v2/' + id
  const response = yield call(get, url)

  switch(response?.status) {
    case 'ok':
      if(response.data) {
        yield put({ type: 'CUSTOMER_FETCHED',
          data: {
            ...response.data,
            customerId: id
          }
        })
      }
      return
    case 'not_found':
    case 'no_content':
        console.warn('Customer not found')
        return
    default:
      yield put({ type: 'FETCHING_CUSTOMER_FAILED' })
      return
  }
}

export function* fetchTemplates({ rowsPerPage, page, searchStr }) {
  const url = "/template"
  const templates = yield call(get, url)

  if(templates && templates.status === 'ok') {
      searchStr = searchStr?.toLowerCase()
      const data = templates.data.map(({name, templateBoxId, appliedTemplateDate}) => ({
        name,
        boxId: templateBoxId?.replace('vehicle/', ''),
        shortId: templateBoxId?.split('_')[1],
        appliedTemplateDate: new Date(appliedTemplateDate).toDateString()
      }));
      const filtered = searchStr 
        ? data.filter(t => (t.name && t.name?.toLowerCase().includes(searchStr)) || (t.shortId && t.shortId?.includes(searchStr))) 
        : data
      const sorted = filtered.sort((a,b) => a.name.localeCompare(b.name))
      const cut = sorted.length > rowsPerPage ? sorted.slice((page-1)*rowsPerPage, page*rowsPerPage) : sorted;
      yield put({ type: 'TEMPLATES_FETCHED', data: cut, total: filtered.length })
  }
  else {
    yield put({ type: 'FETCHING_TEMPLATES_FAILED' })
  }
}

export function* fetchTemplate({ boxId, name }) {
  const url = `/template/${boxId.replace('vehicle/', '')}`
  const response = yield call(post, url, { body: JSON.stringify({ name }) }, 'query')

  switch(response?.status) {
    case 'ok':
      if(response.data) {
        yield put({ type: 'TEMPLATE_FETCHED',
          data: response.data
        })
      }
      break
    case 'not_found':
    case 'no_content':
      console.warn('Template not found')
      break
    default:
      yield put({ type: 'FETCHING_TEMPLATE_FAILED' })
      break
  }
}

export function* addVehicleToCustomer({ vehicleId, tag }) {
  const command = {
    'organisations': [tag],
    'id': 'vehicle/' + vehicleId,
  }

  const result = yield call(post, '/cmd/box/connecttocustomer', { body: JSON.stringify(command) })

  if (result && result.status) {
    const vehicle = yield call(get, "/box/" + vehicleId)
    if(vehicle && vehicle.status === 'ok')
      yield put({type:'VEHICLE_ADDED_TO_CUSTOMER', data: vehicle.data})
    else
      yield put({type:'ADDING_VEHICLE_TO_CUSTOMER_FAILED', id: vehicleId})
  }
}

export function* removeVehicleFromCustomer({ vehicleId, tags }) {
  const command = {
    'id': 'vehicle/' + vehicleId,
    'organisations': tags
  }
  const result = yield call(post, '/cmd/box/removefromcustomer', { body: JSON.stringify(command) })
  if (result && result.status)
    yield put({type:'VEHICLE_REMOVED_FROM_CUSTOMER', data: {vehicleId: vehicleId, tags}})
  else
    yield put({type:'REMOVING_VEHICLE_FROM_CUSTOMER_FAILED', data: {vehicleId: vehicleId, tags}})
}

export function* fetchFirmwares() {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  let token = tokenResult.data.accesstoken

  let result = yield call(get,'/firmware', firmwareServerUrl(), token)

  if (result && result.status) {
    yield put({type: 'AVAILABLE_FIRMWARES_FETCHED', firmwares: result.data})
  }
  else {
    yield put({ type: 'FETCHING_FIRMWARES_FAILED' })
  }
}

export function* uploadFirmware({data}) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(upload, firmwareServerUrl() + '/firmware/upload',{body: data, method: 'POST'}, tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'FIRMWARE_UPLOADED', data: result.data})
    }
    else {
      yield put({type: 'FIRMWARE_ALREADY_UPLOADED', data: result.data})
    }
  }
}

export function* fetchFirmwareBlob({url, name}) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  let token = tokenResult.data.accesstoken

  const blob = yield call(fetchBlob, '/firmware/' + url ,firmwareServerUrl(), token, name)

  if (blob) {
    console.warn(blob)
  }
  else {
    return null
  }
}

export function* designateFirmwareAsStable({ firmwareType, version }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(upload, firmwareServerUrl() + '/firmware/upgradetostable/' + firmwareType + '/' + version, {method: 'PATCH'}, tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'FIRMWARE_UPGRADED', data: result.data})
    }
    else {
      yield put({type: 'FAILED_UPGRADING_FIRMWARE', data: result.data})
    }
  }
}

export function* deleteFirmware({ firmwareType, version }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(upload, firmwareServerUrl() + '/firmware/delete/' + firmwareType + '/' + version, {method: 'DELETE'}, tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'FIRMWARE_DELETED', oldType: firmwareType, oldVersion: version})
    }
    else {
      yield put({type: 'FAILED_DELETING_FIRMWARE', data: result.data})
    }
  }
}

export function* remoteUpgrade({ telematicsId, firmwareType, version }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult?.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
    yield put({type: 'FAILED_REMOTE_UPGRADE'})
    return
  }

  yield put({type: 'SENDING_REMOTE_UPGRADE_REQUEST', oldType: firmwareType, oldVersion: version})

  const result = yield call(upload, firmwareServerUrl() + '/IotDevice/RemoteFirmwareUpgrade/' + telematicsId + '/' + firmwareType + '?version=' + version, {method: 'PATCH'}, tokenResult.data.accesstoken)

  if (result && result.status === 'ok') {
    yield put({type: 'REMOTE_UPGRADE_REQUEST_SENT', oldType: firmwareType, oldVersion: version})
  }
  else {
    yield put({type: 'FAILED_REMOTE_UPGRADE', data: result.data})
  }
}

export function* fetchIsUpgrading({ telematicsId }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(get,'/IotDevice/CheckIfUpgradeInProgress/' + telematicsId, firmwareServerUrl(), tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'REMOTE_UPGRADE_STATUS_RECEIVED', data: result.data, telematicsId})
    }
    else {
      yield put({type: 'FAILED_GETTING_REMOTE_UPGRADE_STATUS', data: result.data, telematicsId})
    }
  }
}

export function* fetchDebugStatus({ telematicsId }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(get,'/IotDevice/CheckIfInDebugMode/' + telematicsId, firmwareServerUrl(), tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'DEBUG_STATUS_RECEIVED', data: result.data})
    }
    else {
      yield put({type: 'FAILED_GETTING_DEBUG_STATUS', data: result.data})
    }
  }
}

export function* setDebugStatus(state, { telematicsId }) {
  let tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const path = state ? '/IotDevice/ActivateGnssDebug/' : '/IotDevice/DeactivateGnssDebug/'
  const result = yield call(upload, firmwareServerUrl() + path + telematicsId, {method: 'PATCH'}, tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'DEBUG_STATUS_REQUESTED', data: state})
    }
    else {
      yield put({type: 'FAILED_REQUESTING_DEBUG_STATUS', data: result.data})
    }
  }
}

export function* fetchDeviceTwin({ telematicsId }) {
  const tokenResult = yield call(get, '/box/firmware/token')

  if (!tokenResult || !tokenResult.status) {
    console.log('could not fetch token:', tokenResult.status)
    yield put({type:'FETCH_TOKEN_FAILED'})
  }

  const result = yield call(get,'/IotDevice/GetDeviceTwin/' + telematicsId, firmwareServerUrl(), tokenResult.data.accesstoken)

  if (result && result.status) {
    if (result.status === 'ok') {
      yield put({type: 'FETCHED_DEVICE_TWIN', data: result.data, telematicsId})
    }
    else {
      yield put({type: 'FAILED_FETCHING_DEVICE_TWIN', data: result.data, telematicsId})
    }
  }
}

function* fetchBlob (url, server_url, optionalToken, name) {

  const result = yield new Promise((accept, reject) => {
    var req = new XMLHttpRequest();
        req.open("GET", server_url + url, true);

        req.responseType = "blob";
        req.setRequestHeader('Authorization', 'Bearer ' + optionalToken)

        req.onload = function(event) {
            var resp = req.response;
            if(resp) {
                accept(resp);
            }
        };

        req.onerror = (event) => {
          console.warn(event)
          reject(event)
        }

        req.send(null);
    })
  .then(function(data) {
    fileDownload(data, name);
  })
  .catch( (err) => {
    console.warn('catcherrorGetImage', err)
  })
  return result
}

export function* fetchUser({ email }) {
  const result = yield call(get, `/customer/user/${email}`)
  if (result && result.status === 'ok') {
    yield put({type: 'FETCHED_USER', data: result.data, email: email})
  }
  else yield put({ type: 'FETCHING_USER_FAILED', email: email })
}


export function* saveConfigAsTemplate({ vehicleId, templateName }) {
  const command ={
    id: 'vehicle/' + vehicleId,
    name: templateName
  }

  const url = '/cmd/box/createtemplate' 

  const result = yield call(post, url, {body: JSON.stringify(command)}, 'tech')

  try {
    if (result && result.status === 'ok') {
      yield put({ type: 'TEMPLATE_SAVED', data: { templateName: templateName, boxId: vehicleId }} )
    }
  } catch (error) {
    yield put({ type: 'SAVING_TEMPLATE_FAILED', data: { templateName: templateName, boxId: vehicleId } })
    console.error('Error:', error);
  }
}
  

  export function* applyTemplateToBox({ vehicleId, templateName }) {

    const command ={
      id: 'vehicle/' + vehicleId,
      name: templateName
    }
    console.log('vehicleId', vehicleId)
    const url = '/cmd/box/applytemplate' 
  
    console.log('URL:', url)
  
      const result = yield call(post, url, {body: JSON.stringify(command)}, 'tech')
  
    
      try {
      if (result && result.status === 'ok') {
        console.log('result', result)
      }
    } catch (error) {
      console.error('Error:', error);
    }
    }
