import React, { useEffect, useState } from "react"
import { Modal } from "@material-ui/core"
import Fade from '@material-ui/core/Fade'
import View from '@modul-connect/shared/components/atoms/view'
import { H1, H3, A } from '@modul-connect/shared/components/atoms/text'
import Button from '@modul-connect/shared/components/atoms/button'
import modal from '@modul-connect/shared/components/atoms/modal'
import { connect } from "react-redux"
import { fetchFirmwares, remoteUpgrade } from "../../../../../state/actions/firmwares"
import { checkIfCurrent, compareVersions, getType, getFirmwareType, checkIfGreaterMajorVersion } from '../../../../../utils/firmwares'
import theme from "@modul-connect/shared/theme"


function getFirmwarePreview(firmwares) {
  if (!firmwares || !(firmwares.all || firmwares.betas))
    return null

  const result = {}

  for (const list of ['all', 'betas']) {
    for (const firmware of firmwares[list]) {
      if (!result[firmware.product])
        result[firmware.product] = {}

      if (!result[firmware.product][firmware.type])
        result[firmware.product][firmware.type] = []

      result[firmware.product][firmware.type].push(firmware.version)
    }
  }

  return result
}

function getActionText(vehicle, product, type, version) {
  if (!version)
    return 'Install'

  const typeFirmware = getType(vehicle, product, type)
  if (!typeFirmware)
    return 'Install'

  switch (compareVersions(typeFirmware.version, version)) {
    case -1:
      return 'Downgrade'
    case 0:
      return 'Reinstall'
    case 1:
      return 'Upgrade'
    default:
      return 'Install'
  }
}

function getFirmwareListItem(vehicle, selectedProduct, selectedType, version, index, setSelectedVersion) {
  const disabled = checkIfGreaterMajorVersion(vehicle, selectedProduct, selectedType, version) 
  const current = checkIfCurrent(vehicle, selectedProduct, selectedType, version)

  return (
    <A
      extend={[{ marginBottom: '5px' }, disabled ? { color: theme.colors.grey } : {}]}
      key={index}
      onClick={() => {
        if (disabled)
          return

          setSelectedVersion(version)
      }}
    >
      {version}
      {current && ' (current)'}
      {disabled && ' - cannot upgrade major version remotely'}
    </A>
  )
}

const ModalRemoteUpgrade = ({
  openModal,
  onClose,
  fetchFirmwares,
  remoteUpgrade,
  firmwares,
  vehicle,
  firmware_upgrade_request,
  singleProduct,
  singleType
}) => {
  const [firmwaresPrepared, setFirmwaresPrepared] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedVersion, setSelectedVersion] = useState(null)
  const [requestSent, setRequestSent] = useState(false)

  useEffect(() => {
    if (openModal)
      fetchFirmwares()
  }, [fetchFirmwares, openModal])

  useEffect(() => {
    setFirmwaresPrepared(getFirmwarePreview(firmwares))
    if (singleProduct) {
      setSelectedProduct(singleProduct)
    }
    if (singleType) {
      setSelectedType(singleType)
    }
  }, [firmwares])

  const resetModal = () => {
    setFirmwaresPrepared(null)
    setSelectedProduct(null)
    setSelectedType(null)
    setSelectedVersion(null)
    setRequestSent(false)
  }

  return (
    <Modal
      open={openModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={openModal}>
        <div >
          <View extend={modal.container_medium}>
            {requestSent ?
              <React.Fragment>
                <View extend={modal.header}>
                  <H1 text={"Remote upgrade sending"} />
                  {selectedVersion &&
                    <View extend={{ position: 'absolute', left: '50%' }}>
                      <H1 text={selectedProduct + ' ' + selectedType + ': ' + selectedVersion} />
                    </View>
                  }
                </View>
                <View extend={modal.content}>
                  {(firmware_upgrade_request === 'success' &&
                    <H3 noPadding>Request has been sent</H3>)
                    ||
                    (firmware_upgrade_request === 'failed' &&
                      <H3 noPadding>Failed sending request</H3>)
                    ||
                    (firmware_upgrade_request === 'sending' &&
                      <H3 noPadding>Sending request...</H3>)
                  }
                </View>
                <View extend={modal.footer}>
                  <Button noBg cancel disabled={false} children={'Go back'} onClick={() => setRequestSent(false)} />
                  <Button noBg cancel disabled={false} children={'Close'} onClick={() => { resetModal(); onClose(); }} />
                </View>
              </React.Fragment>
              :
              <React.Fragment>
                <View extend={modal.header}>
                  <H1 text={"Remote upgrade"} />
                  {selectedVersion &&
                    <View extend={{ position: 'absolute', left: '50%', '> h1': { color: theme.colors.grey } }}>
                      <H1 text={selectedProduct + ' ' + selectedType + ': ' + selectedVersion} />
                    </View>
                  }
                </View>
                <View extend={modal.content}>
                  <H3 noPadding>Choose a firmware to remotely upgrade</H3>
                  {firmwaresPrepared &&
                    (!singleProduct || selectedProduct === singleProduct) &&
                    (!singleType || selectedType === singleType) ? // state sometimes needs some time to update
                    <View extend={{ width: '100%', display: 'inline-flex' }}>
                      <View extend={{ width: '50%' }}>
                        {Object.keys(firmwaresPrepared).map((product, index1) =>
                          (!singleProduct || product == singleProduct) &&
                          <React.Fragment key={index1}>
                            <H3>{product}</H3>
                            {
                              Object.keys(firmwaresPrepared[product]).map((type, index2) =>
                                (!singleType || type == singleType) &&
                                <A
                                  key={index1 + '_' + index2}
                                  onClick={() => {
                                    setSelectedProduct(product)
                                    setSelectedType(type)
                                    setSelectedVersion(null)
                                  }}
                                >
                                  {type}
                                </A>
                              )
                            }
                          </React.Fragment>
                        )}
                      </View>
                      {selectedProduct && selectedType && selectedProduct !== 'none' && selectedType !== 'none' &&
                        <View>
                          <H3>Select {selectedProduct.toLowerCase()} {selectedType.toLowerCase()} version:</H3>
                          {firmwaresPrepared && firmwaresPrepared[selectedProduct] && firmwaresPrepared[selectedProduct][selectedType] &&
                            firmwaresPrepared[selectedProduct][selectedType].map((version, index) =>
                              getFirmwareListItem(vehicle, selectedProduct, selectedType, version, index, setSelectedVersion)
                            )
                          }
                        </View>
                      }
                    </View>
                    :
                    <H3>Loading...</H3>
                  }
                </View>
                <View extend={modal.footer}>
                  <Button noBg cancel disabled={false} children={'Cancel'} onClick={() => { resetModal(); onClose(); }} />
                  <Button
                    noBg
                    disabled={selectedVersion == null}
                    children={getActionText(vehicle, selectedProduct, selectedType, selectedVersion)}
                    onClick={() => {
                      setRequestSent(true)
                      remoteUpgrade(vehicle.telematicsData.telematicsId, selectedProduct, selectedType, selectedVersion)
                    }}
                  />
                </View>
              </React.Fragment>
            }
          </View>
        </div>
      </Fade>
    </Modal>
  )
}


const mapStateToProps = ({
  firmwares, vehicle, firmware_upgrade_request
}) => ({
  firmwares, vehicle, firmware_upgrade_request
})

const mapDispatchToProps = dispatch => ({
  fetchFirmwares: () => dispatch(fetchFirmwares()),
  remoteUpgrade: (telematicsId, product, type, version) => dispatch(remoteUpgrade(telematicsId, getFirmwareType(product, type), version))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalRemoteUpgrade);