

import React from 'react';
import View from '@modul-connect/shared/components/atoms/view'
import { BubbleLoader } from 'react-css-loaders'
import theme from '@modul-connect/shared/theme'
import { H3 } from '../../atoms/text';

export const BubbleLoadingWidget = ({ text }) => {
  return (
    <View extend={style}>
      <BubbleLoader
        color={theme.colors.primary}
        size={5}
      />
      <H3>{text ?? ''}</H3>
    </View>
  )
}

const style = {
  //height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}