import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import View from "@modul-connect/shared/components/atoms/view";
import Login from "./pages/login";
import { connect } from 'react-redux'
import NavBar from "@modul-connect/shared/components/organisms/nav-bar";
import ModalAjaxError from "@modul-connect/shared/components/organisms/modal-ajax-error";
import ModalError from "@modul-connect/shared/components/organisms/errorModal";
import { dismissAjaxError } from "./state/actions/app"
// import SideBar from "@modul-connect/shared/components/organisms/side-bar";
import routes from './routes';
import { logged_in } from "./state/actions/user"
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { sec } from "@modul-connect/shared/auth0/auth0Helper"
import { BubbleLoader } from 'react-css-loaders';
import theme from "@modul-connect/shared/theme";
import { P, H3, H1, H4 } from '@modul-connect/shared/components/atoms/text';
import './index.css'

const App = ({is_logged_in, logged_in, themes, saving, dismissAjaxError, role}) => {

  const [userName, setUserName] = useState(null);
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (saving.moveUser === 'failed') {
      setErrorMessage('There was an error trying to update the user.')
      setError(true)
    }
    else {
      setError(false)
      setErrorMessage("")
    }
  }, [saving.moveUser])

  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    logout
  } = useAuth0();

  if (isAuthenticated) {
    sec.setAccessTokenSilently(getAccessTokenSilently);
    sec.setLogOut(logout)
    if (!is_logged_in)
      logged_in()    
  }


  return (
    <Router>
      <Helmet>
        <title>Modul-Connect backoffice</title>
      </Helmet>
      {is_logged_in && is_logged_in !== 'LOGGING_OUT' ? (
        <View extend={styles.root}>
          <Fragment>
            <NavBar routes={routes} authTool='auth0' username={ user && user.name } themes={ themes }></NavBar>
            <View extend={[styles.main, themes.device === 'tablet' && styles.main_tablet, themes.device === 'mobile' && styles.main_mobile]}>
              {/* <SideBar routes={routes} /> */}
              <View extend={styles.content}>
                {
                  routes.map(route => (
                    <Route path={route.url} exact={!!route.exact} component={route.component} key={route.url} />
                  ))
                }
              </View>
            </View> 
            <ModalAjaxError
              openModal={ saving && saving.ajaxError }
              onClose={ dismissAjaxError }
            />
            <ModalError
              openModal={error}
              onClose={() => {setError(false); setErrorMessage("")}}
              text={errorMessage}
            />
          </Fragment>
        </View>
      ) : (
        <View extend={styles.loaderContainer}>
          <BubbleLoader
            color={ theme.colors.primary }
            size={ 5 }
          />
          <H3>Couldn't find any backoffice account. You will be redirected to the login page...</H3>
        </View> 
     )}
    </Router>
  )
}

const mapStateToProps = ({
  is_logged_in,
  themes,
  saving,
  role
}) => ({
  is_logged_in,
  themes,
  saving,
  role
})

const mapDispatchToProps = dispatch => ({
  logged_in: () => dispatch(logged_in()),
  dismissAjaxError: () => dispatch(dismissAjaxError()),
})

const styles = {
  root: ({ theme: { colors } }) => ({
    position: "relative",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    minHeight: '100vh',
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.bg,
  }),
  main: ({ theme: { colors, layout } }) => ({
    display: "flex",
    flexDirection: "row",
    alignSelf: 'center',
    width: '100%',
    marginTop: layout.navBarHeight,
    paddingBottom: layout.navBarHeight,
    backgroundColor: colors.bg,
  }),
  main_tablet: ({ theme: { colors, layout } }) => ({
    marginTop: layout.navBarHeight_tablet
  }),
  main_mobile: ({ theme: { colors, layout } }) => ({
    marginTop: layout.navBarHeight_mobile
  }),
  content: ({ theme: { colors, layout } }) => ({
    width: '100%',
    overflowY: 'hidden',
    margin: '0px 20px',
    borderRadius:  `${ 1 * layout.grid}px`
  }),
  loaderContainer: ({ theme: { colors, layout } }) => ({
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  })
};


export default connect(mapStateToProps, mapDispatchToProps) (withAuthenticationRequired(
  App,
  { onRedirecting: () =>
      <View extend={styles.loaderContainer}>
          <BubbleLoader
            color={ theme.colors.primary }
            size={ 5 }
          />
          <H3></H3>
     </View>
 }))
