import React, {useEffect, Fragment} from 'react';
import { Modal } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';

import View from '@modul-connect/shared/components/atoms/view'
import { H1, P } from '@modul-connect/shared/components/atoms/text'
import TextField from '@modul-connect/shared/components/molecules/textField'
// import TreeWidget from '@modul-connect/shared/components/molecules/treeWidget'
import Button from '@modul-connect/shared/components/atoms/button';
import modal from '@modul-connect/shared/components/atoms/modal'
import { isEmail } from '@modul-connect/shared/utils/text'


const ModalAddUser = ({
    openModal,
    onClose,
    customer,
    addUser,
    userAlreadyExist,
    incorrectEmail,
    disabled
}) => {
    const [firstName, setFirstName] = React.useState('')
    const [lastName, setLastName] = React.useState('')
    const [email, setEmail] = React.useState('')
    // const [orgTreeTag, setOrgTreeTag] = React.useState(null)
    const [errorFirstName, setErrorFirstName] = React.useState(false)
    const [errorLastName, setErrorLastName] = React.useState(false)
    const [errorEmail, setErrorEmail] = React.useState(false)
    // const [errorOrgTreeTag, setErrorOrgTreeTag] = React.useState(false)

    useEffect(() => {
        if(openModal) {
            setFirstName(null)
            setLastName(null)
            setEmail(null)
            // setOrgTreeTag(null)
            setErrorFirstName(null)
            setErrorLastName(null)
            setErrorEmail(null)
            // setErrorOrgTreeTag(null)
        }
    }, [openModal])

    useEffect(() => {
        if(openModal && ( incorrectEmail || userAlreadyExist)) {
            setErrorEmail(true)
        }
    }, [userAlreadyExist, incorrectEmail])

    const customer_name = customer ? customer.tree?.name : null

    const isName = (name) => {
        if(!name) {
            return false
        }

        if(!name.length) {
            return false
        }

        return true
    }

    const onSave = () => {
        let hasErrors

        if(!isName(firstName)) {
            setErrorFirstName(true)
            hasErrors = true
        }

        if(!isName(lastName)) {
            setErrorLastName(true)
            hasErrors = true
        }

        if(!isEmail(email)) {
            setErrorEmail(true)
            hasErrors = true
        }

        // if(!orgTreeTag) {
        //     setErrorOrgTreeTag(true)
        //     hasErrors = true
        // }

        if(hasErrors) {
            return
        }

        const data = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            name: firstName.trim() + " " + lastName.trim(),
            email: email.trim(),
            organisation: customer.customerId,
        }

        addUser(data)
    }

    const getErrorEmailText = () => {
        if(userAlreadyExist) {
            return '- User already exist'
        }

        if (incorrectEmail) {
            return "- Email does not exist"
        }

        return null
    }

    const errorEmailFeedbackText = getErrorEmailText()

    return (
        <Modal
            open={ openModal }
            onClose={(event, reason) => {
              if (disabled && reason === 'backdropClick') {
                return
              }
              onClose()
            }}            
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Fade in={ openModal }>
                <div >
                    <View extend={ modal.container }>
                        <View extend={ modal.header }>
                            <H1 text={"Add superuser"}/>
                        </View>
                        <View extend={ modal.content }>
                            <TextField
                                type='email'
                                name="Email"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorEmail(!isEmail(e.target.value)) }
                                placeholder="charlie.anderson@modulconnect.com"
                                error={errorEmail}
                                errorText={ errorEmailFeedbackText }
                                onChange={event => setEmail(event.target.value)}
                            />
                            <TextField
                                name="First Name"
                                placeholder="Charlie"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorFirstName(!isName(e.target.value)) }
                                error={errorFirstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            <TextField
                                name="Last Name"
                                placeholder="Anderson"
                                disabled={ disabled }
                                onBlur={ (e) => setErrorLastName(!isName(e.target.value)) }
                                error={errorLastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                            {/* {
                                customer && customer.tree && (
                                    <TreeWidget
                                        title={ 'Organisational unit'}
                                        tree={ customer.tree }
                                        disabled={ disabled }
                                        selected={ orgTreeTag }
                                        onSelect={ (t) => {
                                            setOrgTreeTag(t)
                                            setErrorOrgTreeTag(false)
                                        }}
                                        error={ errorOrgTreeTag }
                                    />
                                )
                            } */}
                        </View>
                        <View extend={ modal.footer }>
                            <Button noBg disabled={ disabled } cancel onClick={ () => onClose() }>
                                Cancel
                            </Button>
                            <Button noBg disabled={ disabled } noPadding onClick={ onSave }>
                                Submit
                            </Button>
                        </View>
                    </View>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalAddUser;